<div *ngIf="type == 'experience'">
  <ul *ngIf="experiences?.length > 0" class="experiences-list">
    <li *ngFor="let experience of experiences" class="experience-item">
      <div class="experience-content">
        <!-- <img
              [src]="complement.imageUrl"
              alt="{{ complement?.name }}"
              class="complement-image"
            /> -->
        <div>
          <p class="experience-name">{{ experience?.name }}</p>

          <p class="experience-quantity">
            {{ experience?.quantity }}
            <span *ngIf="experience?.quantity == 1">unidad</span>
            <span *ngIf="experience?.quantity > 1">unidades</span>
          </p>
        </div>
      </div>
    </li>
  </ul>
  <div *ngIf="experiences?.length === 0">No hay experiencias adicionales.</div>
</div>

<div *ngIf="type == 'complement'">
  <ul *ngIf="complements?.length > 0" class="complements-list">
    <li *ngFor="let complement of complements" class="complement-item">
      <div class="complement-content">
        <!-- <img
          [src]="complement.imageUrl"
          alt="{{ complement?.name }}"
          class="complement-image"
        /> -->
        <div>
          <p class="complement-name">
            {{ complement?.name }}
            <span *ngIf="complement?.variant"
              >(Talla {{ complement?.variant }})</span
            >
          </p>
          <p class="complement-quantity">
            {{ complement?.quantity }}
            <span *ngIf="complement?.quantity == 1">unidad</span>
            <span *ngIf="complement?.quantity > 1">unidades</span>
          </p>
        </div>
      </div>
    </li>
  </ul>
  <div *ngIf="complements?.length === 0">No hay complementos adicionales.</div>
</div>
