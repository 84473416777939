import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { EventSummary } from '../../model/event-summary.interface';
import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-event-detail-summary',
  templateUrl: './event-detail-summary.component.html',
  styleUrls: ['./event-detail-summary.component.scss']
})
export class EventDetailSummaryComponent implements OnInit, OnChanges, AfterViewInit {

  @ViewChild('chartSalesStatus') chartSalesStatusContainer: ElementRef;
  @ViewChild('chartTotalBilled') chartTotalBilledContainer: ElementRef;
  @ViewChild('chartTotalUpselling') chartTotalUpsellingContainer: ElementRef;
  @ViewChild('chartTokensByType') chartTokensByTypeContainer: ElementRef;
  @ViewChild('chartSalesByAffiliate') chartSalesByAffiliateContainer: ElementRef;
  @ViewChild('chartExperiencesByType') chartExperiencesByTypeContainer: ElementRef;
  @ViewChild('chartProductsByType') chartProductsByTypeContainer: ElementRef;

  @Input() eventId: string;

  summary: EventSummary;

  sdk: ChartsEmbedSDK;

  constructor() {

    this.sdk = new ChartsEmbedSDK({
      baseUrl: "https://charts.mongodb.com/charts-project-0-jikbu"
    });
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    if (this.eventId) {
      this.refreshCharts();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes['eventId'] && changes['eventId'].currentValue) {

      this.eventId = changes['eventId'].currentValue;

      if (this.eventId) {
        this.refreshCharts();
      }
    }

  }

  refreshCharts(): void {

    this.loadChartSalesStatus();
    this.loadChartTotalBilled();
    this.loadChartTotalUpselling();
    this.loadChartTokensByType();
    this.loadChartSalesByAffiliate();
    this.loadChartExperiencesByType();
    this.loadChartProductsByType();
  }

  private async loadChartProductsByType() {

    if (this.chartProductsByTypeContainer) {

      const chartProductsByType = this.sdk.createChart({
        chartId: environment.charts.productsByType,
        height: "400px",
        showAttribution: false,
        filter: { "eventId": this.eventId }
      });

      chartProductsByType.render(this.chartProductsByTypeContainer.nativeElement);
    }
  }

  private async loadChartExperiencesByType() {

    if (this.chartExperiencesByTypeContainer) {

      const chartExperiencesByType = this.sdk.createChart({
        chartId: environment.charts.experiencesByEvent,
        height: "400px",
        showAttribution: false,
        filter: { "eventId": this.eventId }
      });

      chartExperiencesByType.render(this.chartExperiencesByTypeContainer.nativeElement);
    }
  }

  private async loadChartSalesByAffiliate() {

    if (this.chartSalesByAffiliateContainer) {
      const chartSalesByAffiliate = this.sdk.createChart({
        chartId: environment.charts.salesByAffiliate,
        height: "400px",
        showAttribution: false,
        filter: { "eventId": this.eventId }
      });

      chartSalesByAffiliate.render(this.chartSalesByAffiliateContainer.nativeElement);
    }
  }

  private async loadChartTokensByType() {
    if (this.chartTokensByTypeContainer) {
      const chartTokensByType = this.sdk.createChart({
        chartId: environment.charts.tokensByType,
        height: "400px",
        showAttribution: false,
        filter: { "eventId": this.eventId }
      });

      chartTokensByType.render(this.chartTokensByTypeContainer.nativeElement);
    }
  }

  private async loadChartTotalUpselling() {
    if (this.chartTotalUpsellingContainer) {
      const chartTotalUpselling = this.sdk.createChart({
        chartId: environment.charts.totalUpselling,
        height: "200px",
        showAttribution: false,
        filter: { "eventId": this.eventId }
      });

      chartTotalUpselling.render(this.chartTotalUpsellingContainer.nativeElement);
    }
  }

  private async loadChartTotalBilled() {
    if (this.chartTotalBilledContainer) {
      const chartTotalBilled = this.sdk.createChart({
        chartId: environment.charts.totalBilled,
        height: "200px",
        showAttribution: false,
        filter: { "eventId": this.eventId }
      });

      chartTotalBilled.render(this.chartTotalBilledContainer.nativeElement);
    }
  }

  private async loadChartSalesStatus(): Promise<void> {
    if (this.chartSalesStatusContainer) {
      const chartSalesStatus = this.sdk.createChart({
        chartId: environment.charts.totalSold,
        height: "200px",
        showAttribution: false,
        filter: { "eventId": this.eventId }
      });

      chartSalesStatus.render(this.chartSalesStatusContainer.nativeElement);
    }
  }
}