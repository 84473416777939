<p-toast position="top-right"></p-toast>
<div class="flex flex-row w-100">
  <div class="flex-grow-1 flex flex-column">
    <h3>{{ experience?.name }}</h3>
    <div [innerHTML]="experience?.description"></div>
  </div>
  <div class="flex-none">
    <h4>
      {{
      experience?.displayPrice / 100 | currency : "EUR" : "symbol" : "1.2-2"
      }}
    </h4>
    <p>Vendidas: {{ experience?.sold }} / {{ experience?.stock }}</p>
  </div>
  <div class="flex flex-column col-3 justify-content-end align-items-center">
    <div class="flex-none">
      <button pButton class="p-button-text p-button-rounded" icon="pi pi-pencil" [disabled]="experience?.sold > 0"
        (click)="editExperience()"></button>
      <button pButton class="p-button-text p-button-rounded" icon="pi pi-trash" [disabled]="experience?.sold > 0"
        (click)="removeExperience()"></button>
    </div>
    <div class="flex-grow-1 flex align-items-center justify-content-center mt-3">
      <button *ngIf="experience?.id !== null" pButton class="full-width" icon="pi pi-send" (click)="createCampaign()">
        Enviar mensaje
      </button>
    </div>
  </div>
</div>

<app-show-message-dialog section="supplement" [eventId]="eventId" [referenceId]="experience.id"
  [show]="messageDialogVisible" (hide)="onMessageDialogHide($event)"></app-show-message-dialog>