<p-card>
  <ng-template pTemplate="title"
    >Tokens ({{ tokens && tokens.length }})</ng-template
  >
  <ng-template pTemplate="subtitle"
    >Estas son los tokens que tiene asignados el usuario</ng-template
  >
  <p-table
    #tokensTable
    [value]="tokens"
    selectionMode="single"
    [(selection)]="selectedToken"
    (onRowSelect)="openToken($event)"
    styleClass="p-datatable-sm"
    [globalFilterFields]="['purchasedAt', 'name', 'typeId', 'price']"
  >
    <ng-template pTemplate="caption">
      <div class="flex">
        <button
          pButton
          label="Borrar filtros"
          class="p-button-outlined ml-3"
          icon="pi pi-filter-slash"
          (click)="clear(usersTable)"
        ></button>
        <span class="p-input-icon-left ml-auto">
          <i class="pi pi-search"></i>
          <input
            pInputText
            type="text"
            (input)="tokensTable.filterGlobal($event.target.value, 'contains')"
            placeholder="Buscar"
          />
        </span>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 20%" pSortableColumn="purchasedAt">
          Adquisición
          <p-columnFilter
            type="text"
            field="purchasedAt"
            display="menu"
          ></p-columnFilter>
          <p-sortIcon field="purchasedAt"></p-sortIcon>
        </th>
        <th style="width: 30%" pSortableColumn="name">
          Nombre
          <p-columnFilter
            type="text"
            field="name"
            display="menu"
          ></p-columnFilter>
          <p-sortIcon field="name"></p-sortIcon>
        </th>
        <th style="width: 30%" pSortableColumn="typeId">
          Tipo
          <p-columnFilter
            type="text"
            field="typeId"
            display="menu"
          ></p-columnFilter
          ><p-sortIcon field="typeId"></p-sortIcon>
        </th>
        <th style="width: 15%" pSortableColumn="price">
          Valor (€)
          <p-columnFilter
            type="text"
            field="price"
            display="menu"
          ></p-columnFilter
          ><p-sortIcon field="price"></p-sortIcon>
        </th>
      </tr>
    </ng-template>
    <ng-template let-token pTemplate="body">
      <tr [pSelectableRow]="token">
        <td>{{ token.purchasedAt | date : "dd/MM/yyy HH:mm" }}</td>
        <td>{{ token.name }}</td>
        <td>{{ token.typeId }}</td>
        <td>{{ (token.price + token.upsellPrice) / 100 | currency : "EUR" : "symbol" : "1.2-2" }}</td>
        <td>
          <i class="pi pi-angle-right"></i>
        </td>
      </tr>
    </ng-template>
  </p-table>
</p-card>
