<div class="flex flex-row align-items-center">
  <div class="flex-none">
    <img [src]="sponsor?.image" alt="Sponsor" class="w-24 h-24 rounded-full" />
  </div>
  <div class="flex-grow-1 ml-3">
    <h5>URL</h5>
    <p>{{ sponsor?.url }}</p>
  </div>
  <div>
    <button
      pButton
      class="p-button-text p-button-rounded"
      icon="pi pi-trash"
      (click)="remove()"
    ></button>
  </div>
</div>
