<p-toast position="top-right"></p-toast>
<div class="card h-full">
  <div class="card-container justify-content-start border-round p-3">
    <p-steps [model]="items" [activeIndex]="activeStep" [hidden]="activeStep===3 && success===true"></p-steps>
    <ng-template [ngIf]="activeStep == 0">
      <app-step-description (next)="onNext($event)" (save)="onSave($event)"
        [data]="formValue?.value.description"></app-step-description>
    </ng-template>
    <ng-template [ngIf]="activeStep == 1">
      <app-step-venue (next)="onNext($event)" (save)="onSave($event)" [data]="formValue?.value.venue"></app-step-venue>
    </ng-template>
    <ng-template [ngIf]="activeStep == 2">
      <app-step-tickets (next)="onNext($event)" (save)="onSave($event)" [startDate]="startDate"
        [data]="formValue?.value.tickets"></app-step-tickets>
    </ng-template>
    <ng-template [ngIf]="activeStep == 3 && success === false">
      <app-step-confirmation [eventId]="eventId" [loading]="loading" (next)="onNext($event)" (save)="onSave($event)"
        (complete)="onComplete($event)" [data]="data.value"></app-step-confirmation>
    </ng-template>
    <ng-template [ngIf]="activeStep == 3 && success === true">
      <app-step-share [eventUrl]="createdEvent?.url" [eventId]="createdEvent?.id"
        [eventName]="createdEvent?.url?.split('/').pop()"></app-step-share>
    </ng-template>
  </div>
</div>