import { Component, Input, OnInit } from '@angular/core';
import { EventTickets } from '../../model/event-detail.interface';

@Component({
  selector: 'app-event-detail-tickets',
  templateUrl: './event-detail-tickets.component.html',
  styleUrls: ['./event-detail-tickets.component.scss']
})
export class EventDetailTicketsComponent implements OnInit {

  @Input() eventId: string;
  @Input() tickets: EventTickets;

  constructor() { }

  ngOnInit(): void {
  }

}
