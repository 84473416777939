<p-dialog header="Añadir relaciones públicas" [(visible)]="showCreateDialog" [style]="{ width: '50vw', height: '80vh' }"
    (onHide)="hideDialog(undefined, undefined)" [modal]="true">

    <div class="field mt-5 flex align-items-center" [formGroup]="relationForm">
        <span style="margin-right:10px">¿Asignar a usuario existente?</span>
        <p-selectButton [options]="[{label: 'Sí', value: true}, {label: 'No', value: false}]" formControlName="assignedToUser"></p-selectButton>
    </div>

    <div *ngIf="relationForm.get('assignedToUser').value === true">
        <div class="field mt-5">
            <span class="p-float-label">
                <p-autoComplete [(ngModel)]="selectedUser" [suggestions]="results" (completeMethod)="search($event)"
                    (onSelect)="selectResult($event)" field="email">
                    <ng-template let-value pTemplate="item">
                        <div class="flex flex-column">
                            <span class="form-header">{{value.fullName}}</span>
                            <span class="form-label">{{value.email}}</span>
                        </div>
                    </ng-template>
                </p-autoComplete>
                <label for="name" class="form-label">Buscar usuario por email</label>
            </span>
        </div>
    </div>

    <div *ngIf="relationForm.get('assignedToUser').value === false">
        <div class="field mt-5" [formGroup]="relationForm">
            <span class="p-float-label">
                <div class="flex flex-column">
                    <input id="linkName" type="text" pInputText formControlName="linkName">
                    <label for="linkName" id="linkname-label">Nombre del enlace</label>
                </div>
            </span>
        </div>
    </div>

    <form [formGroup]="relationForm">
        <h3>Seleccionar entradas</h3>
        <ng-container formArrayName="ticketLimit" *ngIf="tickets && tickets.length>0">
            <ng-container *ngFor="let additionalFieldForm of ticketLimit.controls; let i = index">
                <div [formGroup]="additionalFieldForm" class="flex flex-column">
                    <div class="flex flex-row align-items-center justify-content-between">
                        <div>
                            <p-checkbox [id]="'enabled-'+i" formControlName="enabled" [binary]="true"
                                styleClass="mr-2"></p-checkbox>
                            <label [for]="'enabled-'+i"
                                class="header-label">{{additionalFieldForm.controls['name'].value}}</label>
                        </div>
                        <div class="flex flex-column">
                            <div>
                                <p-checkbox [disabled]="additionalFieldForm.controls['enabled'].value===false"
                                    [id]="'reserved-'+i" formControlName="reserved" [binary]="true"
                                    styleClass="mr-2"></p-checkbox>
                                <label [for]="'reserved-'+i" class="form-label">Reservar capacidad</label>
                            </div>
                            <div class="field mt-5" *ngIf="additionalFieldForm.controls['reserved'].value===true">
                                <span class="p-float-label">
                                    <p-inputNumber [id]="'amount-'+i" formControlName="amount"
                                        class="text-base text-color surface-overlay surface-border border-round appearance-none outline-none focus:border-primary w-full"></p-inputNumber>
                                    <label for="ticketsNumber">Tokens reservados</label>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <p-divider></p-divider>
            </ng-container>
        </ng-container>
        <div class="field mt-5">
            <p-checkbox id="sendMail" [binary]="true" styleClass="mr-2" formControlName="sendMail"></p-checkbox>
            <label for="sendMail" class="form-label">Enviar enlace por email</label>
        </div>
        <button pButton pRipple *ngIf="!loading" [disabled]="!relationForm.valid" label="Añadir RRPP" class="w-full"
            (click)="create()"></button>
        <button pButton pRipple *ngIf="loading" [disabled]="loading" icon="pi pi-spin pi-spinner" iconPos="right"
            label="Añadir RRPP" class="w-full"></button>
    </form>
</p-dialog>

<app-create-dialog [displayCreateDialog]="showCreateUserDialog"
    (hideDialog)="hideDialog('create-user', $event)"></app-create-dialog>