<p-table #publicRelationsTable [value]="relations$ | async" selectionMode="single"
    [(selection)]="selectedPublicRelations" (onRowSelect)="openPublicRelations($event)" responsiveLayout="scroll"
    styleClass="p-datatable-sm" [globalFilterFields]="[
    'slug',
    'user',
    'active'
  ]">
    <ng-template pTemplate="caption">
        <div class="flex">
            <button [disabled]="blockDownload" pButton pRipple type="button" label="Añadir relaciones"
                icon="pi pi-user-plus" (click)="create()"></button>
            <button pButton label="Borrar filtros" class="p-button-outlined ml-3" icon="pi pi-filter-slash"
                (click)="clear(publicRelationsTable)"></button>
            <span class="p-input-icon-left ml-auto">
                <i class="pi pi-search"></i>
                <input pInputText type="text" (input)="
            publicRelationsTable.filterGlobal($event.target.value, 'contains')
          " placeholder="Buscar" />
            </span>
        </div>
    </ng-template>
    <ng-template pTemplate="header">
        <tr>
            <th pSortableColumn="slug">
                Enlace <p-sortIcon field="slug"></p-sortIcon>
                <p-columnFilter type="text" field="slug" display="menu"></p-columnFilter>
            </th>
            <th pSortableColumn="user">
                Usuario <p-sortIcon field="user"></p-sortIcon>
                <p-columnFilter type="text" field="user" display="menu"></p-columnFilter>
            </th>
            <th pSortableColumn="active">
                Activo
                <p-sortIcon field="active"></p-sortIcon>
                <p-columnFilter field="active" matchMode="in" display="menu" [showMatchModes]="false"
                    [showOperator]="false" [showAddButton]="false">
                    <ng-template pTemplate="header">
                        <div class="px-3 pt-3 pb-0">
                            <span class="font-bold">Activo</span>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                        <p-multiSelect [ngModel]="value" [options]="[
                { id: true, name: 'Si' },
                { id: false, name: 'No' }
              ]" placeholder="Any" (onChange)="filter($event.value)" optionLabel="name" optionValue="id">
                            <ng-template let-option pTemplate="item">
                                <div class="inline-block vertical-align-middle">
                                    <span class="ml-1 mt-1">{{ option.name }}</span>
                                </div>
                            </ng-template>
                        </p-multiSelect>
                    </ng-template>
                </p-columnFilter>
            </th>
            <th></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-relation>
        <tr [pSelectableRow]="relation">
            <td>
                {{ relation.slug }}
            </td>
            <td>
                <a [routerLink]="['/users/' + relation.userId]">{{ relation.userEmail }}</a>
            </td>
            <td>
                <div class="flex justify-content-center">
                    <i *ngIf="relation.active === true" pTooltip="Activo" tooltipPosition="top" class="pi pi-check"
                        style="color: green; font-weight: 600"></i>
                    <i *ngIf="relation.active === false" class="pi pi-clock" style="color: orange; font-weight: 600"
                        pTooltip="Inactivo" tooltipPosition="top"></i>
                </div>
            </td>
            <td>
                {{active}}
            </td>
            <td>
                <button pButton pRipple type="button" icon="pi pi-link" pTooltip="Copiar enlace" tooltipPosition="top"
                  class="p-button-rounded p-button-text" (click)="copyLink(relation.slug)"></button>
                <button pButton pRipple type="button" pTooltip="Pausar" tooltipPosition="top" class="p-button-text"
                    icon="pi pi-pause" (click)="pauseRelation(relation.id, relation)"></button>
                <button pButton pRipple type="button" pTooltip="Eliminar" tooltipPosition="top" class="p-button-text"
                    icon="pi pi-trash" (click)="removeRelation(relation.id)"></button>
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
        <tr>
            <td colspan="5">Todavía no hay relaciones públicas.</td>
        </tr>
    </ng-template>
</p-table>

<app-create-relations-dialog [eventId]="eventId" [showCreateDialog]="showCreateDialog"
    (onHide)="hideDialog()"></app-create-relations-dialog>