import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from './model/user.interface';
import { UserService } from './service/user.service';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class UserComponent implements OnInit {
  states: any[] = [
    {
      id: 'ACTIVE',
      name: 'Activo',
    },
    {
      id: 'INVITED',
      name: 'Invitado',
    },
    {
      id: 'INVITED_BY_ADMIN',
      name: 'Invitado',
    },
    {
      id: 'REGISTERED',
      name: 'Registrado',
    },
  ];
  users: User[] = [];
  items: any[];

  selectedUser: User;
  displayCreateDialog: boolean;
  displayImportDialog: boolean;
  displayDetailDialog: boolean;

  constructor(private userService: UserService, private router: Router) {}

  ngOnInit(): void {
    this.displayImportDialog = false;
    this.displayCreateDialog = false;
    this.displayDetailDialog = false;
    this.refreshUsers();

    this.items = [
      {
        label: 'Importar fans',
        icon: 'pi pi-upload',
        command: () => {
          this.displayImportDialog = true;
        },
        tooltipOptions: {
          tooltipLabel: 'Importar fans',
          tooltipPosition: 'left'
        }
      },
      {
        label: 'Crear',
        icon: 'pi pi-plus',
        command: () => {
          this.displayCreateDialog = true;
        },
        tooltipOptions: {
          tooltipLabel: 'Crear fan',
          tooltipPosition: 'left'
        }
      }
    ];
  }

  showImportDialog(): void {
    this.displayImportDialog = true;
  }

  showDetailDialog(userId: string): void {
    this.displayDetailDialog = true;
    const index = this.users.findIndex((user) => user.id === userId);
    if (index !== -1) {
      this.selectedUser = this.users[index];
    }
  }

  hideDialog(dialogId: string): void {
    if (dialogId === 'import') {
      this.displayImportDialog = false;
    }
    if (dialogId === 'create') {
      this.displayCreateDialog = false;
    }

    this.refreshUsers();
  }

  refreshUsers(): void {
    this.userService
      .getUsers()
      .subscribe((response) => (this.users = response));
  }

  getFullName(name: string, surname1: string, surname2: string): string {
    let fullName = name == null ? '' : name;

    fullName += surname1 != null ? ' ' + surname1 : '';
    fullName += surname2 != null ? ' ' + surname2 : '';

    if (fullName.trim() === '') {
      fullName = '-';
    }

    return fullName;
  }

  getState(state: string): string {
    switch (state) {
      case 'ACTIVE':
        return 'Activo';
      case 'INVITED':
        return 'Invitado';
      case 'INVITED_BY_ADMIN':
        return 'Invitado';
      case 'REGISTERED':
        return 'Registrado';
      default:
        return '';
    }
  }

  openUser($event) {
    this.router.navigate(['/users', $event.data.id]);
  }

  clear(table: Table) {
    table.clear();
  }
}
