<div class="stepsdemo-content">
  <p-card>
    <ng-template pTemplate="title"> Personalización </ng-template>
    <ng-template pTemplate="subtitle">
      Configura las opciones de personalización que tendrá el asistente. Es hora de echar a volar la imaginación.
    </ng-template>
    <ng-template pTemplate="content">
      <div class="flex flex-column" *ngIf="experiences && experiences.length > 0; else noExperiences">
        <div *ngFor="let experience of experiences">
          <app-experience-item [eventId]="eventId" [experience]="experience" (onUpdate)="updateExperience($event)"
            (onRemove)="removeExperienceFromList($event)"></app-experience-item>
          <p-divider></p-divider>
        </div>
      </div>
      <ng-template #noExperiences>
        <p>No hay personalización</p>
      </ng-template>
      <button pButton class="p-button-text" label="Añadir personalización" (click)="addExperience()"></button>
    </ng-template>
    <ng-template pTemplate="footer">
      <div class="grid grid-nogutter justify-content-between"></div>
    </ng-template>
  </p-card>
</div>

<p-dialog header="Añadir personalización" [modal]="true" [(visible)]="display">
  <form [formGroup]="experienceForm">
    <div class="field">
      <label for="name">Nombre *</label>
      <input id="name" formControlName="name" type="text" pInputText
        class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" />
      <small *ngIf="
          this.experienceForm.get('name')?.invalid &&
          (this.experienceForm.get('name')?.dirty ||
            this.experienceForm.get('name')?.touched)
        " id="name-help" class="p-error block">El nombre es obligatorio</small>
    </div>
    <div class="field">
      <label for="itemDescription">Descripción *</label>
      <p-editor formControlName="description" [style]="{ height: '200px' }">
        <ng-template pTemplate="header">
          <span class="ql-formats">
            <select class="ql-header">
              <option value="1"></option>
              <option value="2"></option>
              <option value="3"></option>
              <option value="4"></option>
              <option value="5"></option>
              <option value="6"></option>
              <option value="false" selected></option>
            </select>
            <button type="button" class="ql-bold" aria-label="Bold"></button>
            <button type="button" class="ql-italic" aria-label="Italic"></button>
            <button type="button" class="ql-underline" aria-label="Underline"></button>
            <button type="button" class="ql-strike" aria-label="Strike"></button>
            <button type="button" class="ql-list" value="ordered"></button>
            <button type="button" class="ql-list" value="bullet"></button>
          </span>
        </ng-template>
      </p-editor>
    </div>
    <div class="field flex flex-column">
      <label for="image">Imagen del complemento *</label>
      <app-image-upload [url]="this.experienceForm.get('images')?.value" [multiple]="true" alt="Image"
        recommendedHeight="100px" recommendedProp="1:1" (onUpload)="onUpload($event, 'product')"
        (onRemove)="removeImage($event, 'product')"></app-image-upload>
    </div>
    <div class="field">
      <label for="regularPrice">Precio *</label>
      <app-price-input [defaultPrice]="defaultPrice" [includeTaxes]="includeTaxes" [fee]="ticketFee" tax="21"
        (onPriceChange)="onExperiencePriceChange($event)"></app-price-input>
      <small *ngIf="
          this.experienceForm.get('regularPrice')?.invalid &&
          (this.experienceForm.get('regularPrice')?.dirty ||
            this.experienceForm.get('regularPrice')?.touched)
        " id="name-help" class="p-error block">El precio es obligatorio</small>
    </div>
    <div class="field">
      <label for="stock">Unidades *</label>
      <p-inputNumber id="stock" formControlName="stock"
        class="text-base text-color surface-overlay p-2 appearance-none outline-none focus:border-primary w-full"></p-inputNumber>
    </div>
    <div class="field">
      <p>Selección múltiple</p>
      <div class="flex align-items-center">
        <p-checkbox id="allowMultiple" [binary]="true" styleClass="mr-2" formControlName="allowMultiple"></p-checkbox>
        <label for="accept" class="text-900">Permitir seleccionar más de una unidad por token</label>
      </div>
    </div>
    <div class="field flex flex-column">
      <label for="image">Sponsor</label>
      <app-image-upload [url]="[this.experienceForm.get('sponsor').get('image').value]" [multiple]="false" alt="Image"
        recommendedHeight="100px" recommendedProp="1:1" (onUpload)="onUpload($event, 'sponsor')"
        (onRemove)="removeImage($event, 'sponsor')"></app-image-upload>
    </div>
    <div class="field mt-6">
      <label for="fanDiscount">Descuento para fans</label>
      <p-inputNumber id="fanDiscount" formControlName="fanDiscount" suffix="%"
        class="text-base text-color surface-overlay p-2 appearance-none outline-none focus:border-primary w-full"></p-inputNumber>
    </div>
    <div class="field flex flex-column">
      <label for="ticketTypes">Restringir a los tipos de entrada</label>
      <p-multiSelect id="ticketTypes" [options]="ticketTypes" optionLabel="name" optionValue="id"
        formControlName="ticketTypes" optionLabel="name" display="chip"
        emptyMessage="Todos los tipos de entrada"></p-multiSelect>
    </div>
  </form>
  <ng-template pTemplate="footer">
    <button pButton *ngIf="!creatingExperience; else createBlocked"
      [disabled]="experienceForm.invalid || !experienceForm.dirty" class="p-button-success"
      label="Añadir personalización" (click)="createExperience()"></button>
    <ng-template #createBlocked>
      <button pButton [disabled]="true" class="p-button-success" label="Añadir personalización"
        icon="pi pi-spin pi-spinner" iconPos="right"></button>
    </ng-template>
  </ng-template>
</p-dialog>