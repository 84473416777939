<p-toast position="top-right"></p-toast>
<div class="grid">
  <div class="col-6">
    <div class="flex flex-column">
      <h3>{{ ticket?.name }}</h3>
      <div class="sub" [innerHTML]="ticket?.description"></div>
      <p class="sub-bold">Fecha de venta general</p>
      <p class="sub">
        <span *ngIf="ticket?.sellDate; else noSellDate">
          Desde {{ getLocalDate(ticket?.sellDate) | date : "dd/MM/yyyy HH:mm" }}
        </span>
        <span *ngIf="ticket?.sellEndDate">
          Hasta
          {{ getLocalDate(ticket?.sellEndDate) | date : "dd/MM/yyyy HH:mm" }}
        </span>
        <ng-template #noSellDate>No disponible</ng-template>
      </p>
      <p class="sub-bold">Fecha de venta para fans</p>
      <p class="sub">
        <span *ngIf="ticket?.fanSellDate; else noFanSellDate">{{
          getLocalDate(ticket?.fanSellDate) | date : "dd/MM/yyyy HH:mm"
          }}</span>
        <ng-template #noFanSellDate>No disponible</ng-template>
      </p>
    </div>
  </div>
  <div class="col-3">
    <div>
      <h4>
        {{ ticket?.displayPrice / 100 | currency : "EUR" : "symbol" : "1.2-2" }}
      </h4>
      <p class="sub-bold">Descuento para fans</p>
      <p class="sub">
        <span *ngIf="ticket?.discount; else noDiscount">{{ ticket?.discount }}%</span>
        <ng-template #noDiscount>No disponible</ng-template>
      </p>
      <p class="sub-bold">Vendidas</p>
      <p class="sub">{{ ticket?.ticketsSold }} / {{ ticket?.ticketsNumber }}</p>
    </div>
  </div>
  <div class="col-3">
    <div class="flex-grow-1 flex align-items-center justify-content-center mb-3">
      <button *ngIf="ticket?.id !== null" pButton class="p-button-text p-button-rounded" icon="pi pi-pencil"
        (click)="editTicket()"></button>
      <button pButton class="p-button-text p-button-rounded" icon="pi pi-trash" [disabled]="ticket?.ticketsSold > 0"
        (click)="removeTicket()"></button>
    </div>
    <div class="flex-grow-1 flex align-items-center justify-content-center mb-3" *ngIf="ticket?.id !== null">
      <p-inputSwitch (onChange)="handleChange($event)" [(ngModel)]="ticket.active"></p-inputSwitch>
    </div>
    <div class="flex-grow-1 flex align-items-center justify-content-center">
      <button *ngIf="ticket?.id !== null" pButton class="full-width" icon="pi pi-plus" (click)="addTickets()">
        Añadir tokens
      </button>
    </div>
    <div class="flex-grow-1 flex align-items-center justify-content-center mt-3">
      <button *ngIf="ticket?.id !== null" pButton class="full-width" icon="pi pi-send" (click)="createCampaign()">
        Enviar mensaje
      </button>
    </div>
  </div>
</div>

<p-dialog header="Generar tokens" [closable]="false" [modal]="true" [(visible)]="addTicketsDialogVisible"
  [style]="{ width: '50vw' }">
  <p>Vas a generar nuevos tokens para este tipo de entrada.</p>
  <p-inputNumber inputId="integeronly" [(ngModel)]="newTickets">
  </p-inputNumber>
  <ng-template pTemplate="footer">
    <p-button (click)="generateNewTickets()" [disabled]="newTickets <= 0 || newTickets > 100000" label="Generar"
      [loading]="generatingTokens" styleClass="p-button-text"></p-button>
    <p-button (click)="addTicketsDialogVisible = false" label="Cancelar" [loading]="generatingTokens" loadingIcon=""
      styleClass="p-button-text"></p-button>
  </ng-template>
</p-dialog>

<app-show-message-dialog section="ticketType" [eventId]="eventId" [referenceId]="ticket.id"
  [show]="messageDialogVisible" (hide)="onMessageDialogHide($event)"></app-show-message-dialog>