import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Sale } from 'src/app/event/model/sale.interface';
import { environment } from 'src/environments/environment';
import { ExportResponse } from '../model/export-response.interface';

@Injectable({
  providedIn: 'root'
})
export class DeliveryService {


  baseUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) { }

  retrieveSales(eventId: string): Observable<Sale[]> {

    const url = `${this.baseUrl}/delivery/findByEvent?eventId=${eventId}`;

    return this.http.get<Sale[]>(url);
  }

  retrieveSalesByToken(tokenId: string): Observable<Sale[]> {

    const url = `${this.baseUrl}/delivery/findByToken?tokenId=${tokenId}`;

    return this.http.get<Sale[]>(url);
  }

  deliverMerch(deliveryId: string): Observable<any> {

    const url = `${this.baseUrl}/delivery/${deliveryId}`;

    return this.http.put<any>(url, {});
  }

  undeliverMerch(deliveryId: string): Observable<any> {

    const url = `${this.baseUrl}/delivery/${deliveryId}`;

    return this.http.delete<any>(url, {});
  }

  export(eventId: string): Observable<ExportResponse> {

    const url = `${this.baseUrl}/export/event/${eventId}/sales`;

    return this.http.get<any>(url);
  }
}
