import { Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { EventService } from '../../service/event.service';
import { Sale } from '../../model/sale.interface';
import { Table } from 'primeng/table';
import { DeliveryService } from 'src/app/shared/services/delivery.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-event-detail-sales',
  templateUrl: './event-detail-sales.component.html',
  styleUrls: ['./event-detail-sales.component.scss']
})
export class EventDetailSalesComponent implements OnInit, OnChanges {

  @Input() eventId: string;

  sales: Sale[];

  constructor(private deliveryService: DeliveryService) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['eventId'] && changes['eventId'].currentValue) {
      this.deliveryService.retrieveSales(changes['eventId'].currentValue).subscribe((sales: Sale[]) => this.sales = sales);
    }
  }



}
