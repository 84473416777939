import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UserService } from '../../service/user.service';
import { TokenPreview } from '../../model/token-preview.interface';
import { Router } from '@angular/router';

@Component({
  selector: 'app-token-history',
  templateUrl: './token-history.component.html',
  styleUrls: ['./token-history.component.scss']
})
export class TokenHistoryComponent implements OnInit, OnChanges {

  @Input() userId: string;
  tokens: TokenPreview[] = [];
  selectedToken!: TokenPreview;

  constructor(private userService: UserService, private router: Router) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes && changes['userId'] && changes['userId'].currentValue) {

      this.userService.getTokenList(changes['userId'].currentValue).subscribe((response: TokenPreview[]) => {
        this.tokens = response;
      })
    }
  }

  openToken($event: any) {

    console.log($event);
    this.router.navigate(['/users', this.userId, 'tokens', $event.data.id]);
  }

}
