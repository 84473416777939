import { Component, Input, OnInit } from '@angular/core';
import { Sale } from 'src/app/event/model/sale.interface';
import { AdditionalExperience } from 'src/app/user/model/ticket-detail.interface';

@Component({
  selector: 'app-complement-experience-list',
  templateUrl: './complement-experience-list.component.html',
  styleUrls: ['./complement-experience-list.component.scss']
})
export class ComplementExperienceListComponent implements OnInit {

  @Input() type: string; // 'complement' or 'experience'
  @Input() complements: Sale[];
  @Input() experiences: AdditionalExperience[];

  constructor() { }

  ngOnInit(): void {

  }

}
