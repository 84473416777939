<p-toast position="top-right"></p-toast>
<div class="card h-full">
  <div class="card-container justify-content-start border-round p-3">
    <div class="flex flex-row align-items-center justify-content-between mb-3">
      <div class="flex-grow-1">
        <h2>{{ event?.description?.name }}</h2>
      </div>
      <div class="flex flex-row align-items-center">
        <div class="mr-3">
          <p-tag [value]="getState(event?.state)" [ngClass]="event?.state==='ACTIVE' ? 'active' : 'not-active'"></p-tag>
        </div>
        <div *ngIf="event?.state === 'DRAFT'">
          <button *ngIf="!publishing; else publishBlocked" class="p-button-success mr-3" label="Publicar" pButton
            pRipple type="button" (click)="publish()"></button>
          <ng-template #publishBlocked>
            <button class="p-button-success mr-3" label="Publicar" icon="pi pi-spin pi-spinner" iconPos="right" pButton
              pRipple type="button" [disabled]="true"></button>
          </ng-template>
        </div>
        <div>
          <button *ngIf="event?.state === 'ACTIVE'; else publishButton" class="p-button-text mr-3" pButton pRipple
            icon="pi pi-stop" pTooltip="Pausar experiencia" tooltipPosition="bottom" type="button"
            (click)="activate(true)"></button>
          <ng-template #publishButton>
            <button class="p-button-text mr-3" pButton pRipple icon="pi pi-play" pTooltip="Activar experiencia"
              tooltipPosition="bottom" type="button" (click)="activate(false)"></button>
          </ng-template>
        </div>
        <div>
          <button *ngIf="event?.hidden;else hideButton" class="p-button-text mr-3" pButton pRipple icon="pi pi-eye"
            pTooltip="Listar en el catálogo" tooltipPosition="bottom" type="button" (click)="setHidden(false)"></button>
          <ng-template #hideButton>
            <button class="p-button-text mr-3" pButton pRipple icon="pi pi-eye-slash" pTooltip="Ocultar del catálogo"
              tooltipPosition="bottom" type="button" (click)="setHidden(true)"></button>
          </ng-template>
        </div>
        <div>
          <button class="p-button-text mr-3" pButton pRipple icon="pi pi-th-large" pTooltip="Catálogo de servicios"
            tooltipPosition="bottom" type="button" (click)="showServices()"></button>
        </div>
        <div>
          <button class="p-button-text mr-3" pButton pRipple icon="pi pi-link" pTooltip="Copiar url"
            tooltipPosition="bottom" type="button" (click)="copyLink()"></button>
        </div>
        <div>
          <button class="p-button-text mr-3" pButton pRipple icon="pi pi-qrcode" pTooltip="Ver QR"
            tooltipPosition="bottom" type="button" (click)="viewQR()"></button>
        </div>
        <div>
          <button class="p-button-text mr-3" pButton pRipple icon="pi pi-volume-up" pTooltip="Notificar a fans"
            tooltipPosition="bottom" type="button" (click)="notifyFans()"
            [disabled]="event?.tenantMembersNotification?.sent ?? false"></button>
        </div>
        <div>
          <button class="p-button-text mr-3" pButton pRipple icon="pi pi-clone" pTooltip="Clonar"
            tooltipPosition="bottom" type="button" (click)="clone()"></button>
        </div>
        <div>
          <button class="p-button-text mr-3" pButton pRipple icon="pi pi-trash" pTooltip="Eliminar"
            tooltipPosition="bottom" type="button" (click)="confirm()"></button>
        </div>
      </div>
    </div>

    <div class="flex flex-row">
      <!-- The secondary menu -->
      <div>
        <p-menu [model]="items"></p-menu>
      </div>
      <!-- The content -->
      <div class="content ml-3">
        <div *ngIf="!section || section === 'summary'">
          <app-event-detail-summary [eventId]="event?.id"></app-event-detail-summary>
        </div>
        <div *ngIf="section === 'description'">
          <app-event-detail-description [eventId]="event?.id"
            [description]="event?.description"></app-event-detail-description>
        </div>
        <div *ngIf="section === 'venue'">
          <app-event-detail-venue [eventId]="event?.id" [venue]="event?.venue"></app-event-detail-venue>
        </div>
        <div *ngIf="section === 'tickets'">
          <app-event-detail-tickets [eventId]="event?.id" [tickets]="event?.tickets"></app-event-detail-tickets>
        </div>
        <div *ngIf="section === 'experiences'">
          <app-event-detail-experiences [eventId]="event?.id"
            [experiences]="event?.experiences"></app-event-detail-experiences>
        </div>
        <div *ngIf="section === 'sponsors'">
          <app-event-detail-sponsors [eventId]="event?.id" [sponsors]="event?.sponsors"></app-event-detail-sponsors>
        </div>
        <div *ngIf="section === 'sales'">
          <app-event-detail-sales [eventId]="event?.id"></app-event-detail-sales>
        </div>
        <div *ngIf="section === 'attendees'">
          <app-event-detail-attendees [eventId]="event?.id"></app-event-detail-attendees>
        </div>
        <div *ngIf="section === 'messages'">
          <app-event-detail-messages [eventId]="event?.id"></app-event-detail-messages>
        </div>
        <div *ngIf="section === 'content'">
          <app-event-detail-content [eventId]="event?.id" [content]="event?.content"></app-event-detail-content>
        </div>
        <div *ngIf="section === 'public-relations'">
          <app-event-detail-public-relations [eventId]="event?.id" [eventUrl]="event?.url"
            [content]="event?.content"></app-event-detail-public-relations>
        </div>
        <div *ngIf="section === 'streaming'">
          <app-event-detail-streaming [eventId]="event?.id" [streaming]="event?.streaming"></app-event-detail-streaming>
        </div>
        <div *ngIf="section === 'moments'">
          <app-event-detail-moments [eventId]="event?.id"></app-event-detail-moments>
        </div>
        <div *ngIf="section === 'coupons'">
          <app-event-detail-coupons [eventId]="event?.id"></app-event-detail-coupons>
        </div>
      </div>
    </div>
  </div>
</div>

<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<p-dialog header="Descargar QR" [modal]="true" [(visible)]="showQRDiaglog">
  <div class="flex flex-column justify-content-center align-items-center">
    <p>Descarga este código QR y utilizalo para atraer tráfico a tu evento.</p>
    <app-event-qr [eventId]="event?.id" [showImage]="true" [filename]="event?.description?.name"></app-event-qr>
  </div>
</p-dialog>

<p-dialog header="Catálogo de servicios" [(visible)]="showServicesDialog" [modal]="true" (onHide)="refreshPage()"
  [style]="{ width: '90vw'}">
  <p>Todo lo que necesitas para convertir tu evento en una experiencia única</p>
  <div class="grid">
    <div *ngFor="let item of catalog" class="col-3 flex align-items-center justify-content-start">
      <app-event-detail-catalog-item [item]="item" [eventId]="eventId"
        [services]="event?.services"></app-event-detail-catalog-item>
    </div>
  </div>
</p-dialog>