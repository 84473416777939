import { HttpClient, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, of, throwError } from 'rxjs';
import { ContentUpload } from 'src/app/shared/model/content-upload.interface';
import { environment } from 'src/environments/environment';
import { JobStatus } from '../model/job-status.interface';
import { User } from '../model/user.interface';
import { Purchase } from '../model/purchase.interface';
import { Ticket } from 'src/app/event/model/ticket.interface';
import { TokenPreview } from '../model/token-preview.interface';
import { Token } from '../model/token.interface';
import { TicketOwnerDetail } from '../model/ticket-owner-detail.interface';
import { TokenMovement } from '../model/token-movement.interface';
import { AdditionalExperience, AdditionalItem } from '../model/ticket-detail.interface';
import { Message } from 'src/app/event/model/message.interface';
import { UserDetail } from '../model/user-detail-interface';
import { UserPreview } from 'src/app/event/model/user-preview.interface';
import { Advertisement } from 'src/app/event/model/advertisement.interface';
import { CartItem } from '../model/cart-item.interface';
import { ThreeDSResponse } from '../model/threeds-response.interface';

@Injectable({
  providedIn: 'root'
})
export class UserService {



  baseUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) { }

  createUser(value: UserDetail): Observable<User> {

    const url = `${this.baseUrl}/users`

    return this.http.post<User>(url, value);
  }

  autocomplete(criteria: any): Observable<UserPreview[]> {

    const url = `${this.baseUrl}/users/search?criteria=${criteria}`;

    return this.http.get<UserPreview[]>(url)
  }

  getUsers(): Observable<User[]> {

    const url = `${this.baseUrl}/users/full`

    return this.http.get<User[]>(url)

  }

  getUserById(userId: string): Observable<User> {

    const url = `${this.baseUrl}/users/${userId}`

    return this.http.get<User>(url)

  }

  getUserEventsById(userId: string): Observable<any[]> {

    const url = `${this.baseUrl}/users/${userId}/events`;

    return this.http.get<any[]>(url);
  }

  getUserPurchasesById(id: string): Observable<Purchase[]> {

    const url = `${this.baseUrl}/users/${id}/purchases`;

    return this.http.get<Purchase[]>(url);
  }

  getUserPurchaseById(id: string): Observable<Purchase> {

    const url = `${this.baseUrl}/purchases/${id}/full`;

    return this.http.get<Purchase>(url);
  }

  getSignedURL(): Observable<ContentUpload> {

    const url = `${this.baseUrl}/users/load`;

    return this.http.get<ContentUpload>(url)
  }

  executeImport(jobId: string, ticketTypeId: string | null, eventId: string | null, notifyUser: boolean | null): Observable<void> {

    const url = `${this.baseUrl}/users/load/${jobId}`;

    let params = new HttpParams();
    if (ticketTypeId && eventId) {
      params = params.append('ticketTypeId', ticketTypeId);
      params = params.append('eventId', eventId);
    }

    params = params.append('notifyUser', notifyUser ? 'true' : 'false');

    return this.http.post<void>(url, null, { params }).pipe(catchError(
      error => throwError(() => error)));;
  }

  getImportStatus(jobId: string): Observable<JobStatus> {
    const url = `${this.baseUrl}/users/load/${jobId}/status`;

    return this.http.get<JobStatus>(url);
  }

  uploadContent(fileuploadurl, file): Observable<any> {

    const headers = new HttpHeaders({ 'Content-Type': 'text/csv' });
    const req = new HttpRequest(
      'PUT',
      fileuploadurl,
      file,
      {
        headers: headers,
        reportProgress: true,
      });
    return this.http.request(req);
  }


  getTicketsForPurchase(purchaseId: string) {

    const url = `${this.baseUrl}/purchases/${purchaseId}/tickets`;

    return this.http.get<Ticket[]>(url)
  }

  /**
   * Gets the token list for the given user
   * @param userId the user id
   * @returns the token list
   */
  getTokenList(userId: string): Observable<TokenPreview[]> {

    const url = `${this.baseUrl}/users/${userId}/tokens`;

    return this.http.get<TokenPreview[]>(url);
  }

  /**
   * Get a token by the given id
   * @param id the token id
   * @returns the token
   */
  getTokenById(tokenId: string): Observable<Token> {

    const url = `${this.baseUrl}/tickets/${tokenId}`;

    return this.http.get<Token>(url);
  }

  removeLabel(userId: string, labelId: string) {

    const url = `${this.baseUrl}/users/${userId}/labels/${labelId}`;

    return this.http.delete<void>(url);
  }

  addLabel(userId: string, $event: string) {

    const url = `${this.baseUrl}/users/${userId}/labels`;

    return this.http.post<void>(url, { labelId: $event });
  }

  resendToken(tokenId: string): Observable<void> {
    const url = `${this.baseUrl}/tickets/${tokenId}/resend`;

    return this.http.post<void>(url, undefined);
  }

  getTokenAssignationInfoById(tokenId: string): Observable<TicketOwnerDetail> {

    const url = `${this.baseUrl}/tickets/${tokenId}/assignation`;

    return this.http.get<TicketOwnerDetail>(url);
  }

  reassignToken(tokenId: string, email: string) {
    const url = `${this.baseUrl}/tickets/${tokenId}/assign/change`;

    return this.http.post<void>(url, { email });
  }


  getTokenMovementsById(tokenId: string): Observable<TokenMovement[]> {

    const url = `${this.baseUrl}/tickets/${tokenId}/expenses`;

    return this.http.get<TokenMovement[]>(url);
  }

  addBalance(tokenId: string, amount: number) {

    const url = `${this.baseUrl}/tickets/${tokenId}/expenses`;

    return this.http.post<any>(url, { amount });
  }

  refundExpense(id: string, purchaseId: string) {

    const url = `${this.baseUrl}/tickets/${id}/expenses/${purchaseId}`;

    return this.http.delete<any>(url);
  }

  getTokenAdditionalExperiencesById(tokenId: string): Observable<AdditionalExperience[]> {

    const url = `${this.baseUrl}/tickets/${tokenId}/experiences`;

    return this.http.get<AdditionalExperience[]>(url);
  }

  getTokenAdditionalItemsById(tokenId: string) {

    const url = `${this.baseUrl}/tickets/${tokenId}/products`;

    return this.http.get<AdditionalItem[]>(url);
  }


  getMessages(tokenId: string): Observable<Message[]> {

    let url = `${this.baseUrl}/tickets/${tokenId}/messages`;

    return this.http.get<Message[]>(url);
  }

  postMessage(tokenId: string, message: any): Observable<void> {

    let url = `${this.baseUrl}/tickets/${tokenId}/messages`;

    return this.http.post<void>(url, message);
  }

  deleteMessage(tokenId: string, messageId: string): Observable<void> {

    let url = `${this.baseUrl}/tickets/${tokenId}/messages/${messageId}`;

    return this.http.delete<void>(url);
  }

  regenerateTicket(tokenId: string, eventId: string, ticketTypeId: string): Observable<void> {

    const url = `${this.baseUrl}/tickets/${tokenId}/regenerate`;

    const payload = {
      eventId: eventId,
      ticketTypeId: ticketTypeId
    };

    return this.http.post<void>(url, payload);
  }

  getTokenUrls(tokenId: string): Observable<{ [key: string]: string }> {
    return this.http.get<{ [key: string]: string }>(`${this.baseUrl}/tickets/${tokenId}/images`);
  }

  getCustomization(tokenId: string, category: string): Observable<Advertisement[]> {
    const url = `${this.baseUrl}/tickets/${tokenId}/customization-admin?mode=${category}`;
    return this.http.get<Advertisement[]>(url);
  }

  upgrade(tokenId: string, cart: CartItem[]): Observable<any> {

    const url = `${this.baseUrl}/tickets/${tokenId}/upgrade-admin`;

    return this.http.post<any>(url, {
      paymentMethodId: undefined,
      tokenUpgrade: cart,
      billingDetails: undefined,
    });
  }

}
