import { Component, LOCALE_ID, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { CookieService, SameSite } from 'ngx-cookie-service';
import { MenuItem } from 'primeng/api/menuitem';
import { getLoggedInUser } from 'src/app/auth/store';
import { environment } from 'src/environments/environment';
import { DomainExists } from './auth/model/domain-exists.interface';
import { Organization } from './auth/model/organization.interface';
import { AuthService } from './auth/service/auth.service';
import { loginSuccess } from './auth/store/actions';
import localeEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { ConfirmationService } from 'primeng/api';
import { LinkUtils } from './event/util/link.util';
import { filter } from 'rxjs';

registerLocaleData(localeEs, 'es');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [{ provide: LOCALE_ID, useValue: 'es' }]
})
export class AppComponent implements OnInit {

  name: string;
  storageValue: number;
  storageText: string;
  tier: string;
  domain: string;
  logoUrl: string;
  currentRoute: string = '';

  items: MenuItem[] = [
    /*{
    label: 'Inicio', routerLink: ['/home'], icon: 'pi pi-home', tabindex: '1', tooltipOptions: {
      tooltipLabel: 'Inicio',
      tooltipPosition: 'bottom'
    }
  },*/
    {
      label: 'Experiencias', routerLink: ['/events'], icon: 'pi pi-calendar', tabindex: '2', tooltipOptions: {
        tooltipLabel: 'Experiencias',
        tooltipPosition: 'bottom'
      }
    },
    {
      label: 'Complementos', routerLink: ['/products'], icon: 'pi pi-shopping-bag', tabindex: '3', tooltipOptions: {
        tooltipLabel: 'Complementos',
        tooltipPosition: 'bottom'
      }
    },
    {
      label: 'Fans', routerLink: ['/users'], icon: 'pi pi-user', tabindex: '4', tooltipOptions: {
        tooltipLabel: 'Fans',
        tooltipPosition: 'bottom'
      }
    },
    {
      label: 'Campañas', routerLink: ['/campaigns'], icon: 'pi pi-send', tabindex: '5', tooltipOptions: {
        tooltipLabel: 'Campañas',
        tooltipPosition: 'bottom'
      }
    },
    {
      label: 'Listas (Beta)', routerLink: ['/lists'], icon: 'pi pi-users', tabindex: '5', tooltipOptions: {
        tooltipLabel: 'Listas (Beta)',
        tooltipPosition: 'bottom'
      }
    }];

  constructor(private store: Store, private cookieService: CookieService, private authService: AuthService, private router: Router, private activatedRoute: ActivatedRoute, private confirmationService: ConfirmationService) { }

  ngOnInit(): void {

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.currentRoute = event.urlAfterRedirects;
    });

    this.activatedRoute.queryParams.subscribe(params => {
      if (params['affiliate']) {
        const cookiePath = environment.cookie.path;
        const cookieOrigin = environment.cookie.origin;
        const cookieSecure = environment.cookie.secure;
        const cookieExpires = environment.cookie.maxAge;
        const cookieSameSite = environment.cookie.sameSite as SameSite;

        const cookieAffiliate = `X-AFFILIATE=${params['affiliate']};path=${cookiePath};domain=${cookieOrigin};max-age=${cookieExpires};samesite=${cookieSameSite}`;
        document.cookie = cookieAffiliate;
      }
    });

    const accessToken = this.cookieService.get('access_token');

    if (accessToken && accessToken !== '') {
      this.authService.getOrganization().subscribe((tenant: Organization) => {
        this.store.dispatch(loginSuccess({ organization: tenant }));
      });
      this.authService.isTenantConfigured().subscribe((isConfigured: DomainExists) => {
        if (isConfigured.exists === false) {
          this.router.navigate(['/auth/complete']);
        }
      })
    }

    this.store.select(getLoggedInUser).subscribe(observer => {

      this.name = observer?.username;
      this.tier = observer?.tier;
      this.domain = observer?.domain;
      if (observer?.logoUrl === null) {
        this.logoUrl = "/assets/images/sample-logo.webp"
      } else {
        this.logoUrl = observer?.logoUrl;
      }
      const usedStorage = Math.round((observer?.usedStorage / 1024 / 1024 * 100) / 100).toFixed(2) + ' GB';
      const assignedStorage = Math.round((observer?.assignedStorage / 1024 / 1024 * 100) / 100).toFixed(2) + ' GB';

      this.storageValue = 100 * (Math.round(((observer?.usedStorage / 1024 / 1024) * 100) / 100)) / (Math.round(((observer?.assignedStorage / 1024 / 1024) * 100) / 100));
      this.storageText = `${usedStorage} utilizados de ${assignedStorage}`;
    });
  }

  openImprovePlan(): void {
    window.open(environment.info.improvePlan, '_blank');
  }

  logout(): void {
    this.confirmationService.confirm({
      message: '¿Seguro/a que quieres cerrar sesión?',
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        window.open(environment.logoutUrl, '_self')
      }
    });
  }

  getInitials(name: string): string {
    if (!name) {
      return '';
    }

    const parts: string[] = name.split(' ');
    let initials: string = '';

    for (var i = 0; i < parts.length; i++) {
      if (parts[i].length > 0 && parts[i] !== '') {
        initials += parts[i][0]
      }
    }

    return initials;
  }

  copyPageLink(): void {

    LinkUtils.copy(this.domain);
  }

  openPage(): void {
    window.open(this.domain, '_blank');
  }

  get outletContainerClass() {
    return {
      'p-5': true,
      'overflow-y-scroll': true,
      'no-scroll-attendees': this.currentRoute.includes('/attendees')
    };
  }

}
