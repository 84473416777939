import { Component, OnInit, SecurityContext } from '@angular/core';
import { ActivatedRoute, NavigationEnd, ParamMap, Router } from '@angular/router';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { EventDetail, EventResponse } from '../../model/event-detail.interface';
import { EventService } from '../../service/event.service';
import { EventState } from '../../util/event-state.util';
import { CreatedEvent } from '../../model/created-event.interface';
import { LinkUtils } from '../../util/link.util';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.scss'],
  providers: [MessageService, ConfirmationService]
})
export class EventDetailComponent implements OnInit {

  eventId: string = '';
  section: string = 'summary';
  event: EventDetail;
  publishing: boolean = false;
  showQRDiaglog: boolean = false;
  showServicesDialog: boolean = false;
  items: MenuItem[] = [];
  eventQRCode: SafeResourceUrl;

  catalog: any[] = [
    {
      id: 'advertisements',
      title: 'Complementos',
      description: 'Vende actividades, productos y servicios de tu tienda con la entrada.',
      icon: 'pi pi-shopping-bag'
    },
    {
      id: 'cashless',
      title: 'Cashless',
      description: 'Habilita pagos durante el evento a través de la entrada sin complicaciones.',
      icon: 'pi pi-wallet'
    },
/*     {
      id: 'content',
      title: 'Contenido',
      description: 'Comparte contenido multimedia con los asistentes y deja un recuerdo para siempre.',
      icon: 'pi pi-images'
    }, */
    {
      id: 'public-relations',
      title: 'RRPP',
      description: 'Gana alcance vendiendo entradas a través de tus relaciones públicas.',
      icon: 'pi pi-users'
    },
    {
      id: 'reassignation',
      title: 'Fan-to-fan',
      description: 'Controla la reventa y permite la reasignación segura entre asistentes.',
      icon: 'pi pi-arrows-h'
    },
    {
      id: 'streaming',
      title: 'Streaming',
      description: 'Emite el evento en directo y permite que los fans lo sigan a través de su token.',
      icon: 'pi pi-video'
    },
    {
      id: 'moments',
      title: 'Momentos',
      description: 'Comparte contenido multimedia con los asistentes y deja un recuerdo para siempre.',
      icon: 'pi pi-images'
    },
    {
      id: 'coupons',
      title: 'Cupones descuento',
      description: 'Habilita cupones descuento para tickets, personalizaciones y complementos.',
      icon: 'pi pi-ticket'
    },
    {
      id: 'offline',
      title: 'Acceso offline',
      description: 'Permite a los asistentes acceder a todo el contenido desde el propio recinto. ¡Basta con que lean un QR!',
      icon: 'pi pi-qrcode'
    }    
  ];

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private eventService: EventService, private messageService: MessageService, private confirmationService: ConfirmationService, private sanitizer: DomSanitizer) {

    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {

      const id = params.get('id');

      this.eventId = id;

      this.eventService.getEventById(id).subscribe((response: EventResponse) => {
        this.event = response.event;

        // load menu after retrieving event to correctly load menu items
        this.items = [
          { label: 'Resumen', routerLink: [`/events/${id}/summary`], icon: 'pi pi-chart-line' },
          { label: 'Descripción', routerLink: [`/events/${id}/description`], icon: 'pi pi-align-justify' },
          { label: 'Celebración', routerLink: [`/events/${id}/venue`], icon: 'pi pi-calendar' },
          { label: 'Tickets', routerLink: [`/events/${id}/tickets`], icon: 'pi pi-ticket' },
          { label: 'Personalización', routerLink: [`/events/${id}/experiences`], icon: 'pi pi-angle-double-up' },
          { label: 'Sponsors', routerLink: [`/events/${id}/sponsors`], icon: 'pi pi-money-bill' },
          { label: 'Asistentes', routerLink: [`/events/${id}/attendees`], icon: 'pi pi-users' },
          { label: 'Ventas', routerLink: [`/events/${id}/sales`], icon: 'pi pi-shopping-bag' },
          { label: 'Mensajes', routerLink: [`/events/${id}/messages`], icon: 'pi pi-comment' },
          { label: 'RRPP', routerLink: [`/events/${id}/public-relations`], icon: 'pi pi-user-plus', visible: this.hasModule('public-relations') },
          { label: 'Contenido', routerLink: [`/events/${id}/content`], icon: 'pi pi-images', visible: this.hasModule('content') },
          { label: 'Streaming', routerLink: [`/events/${id}/streaming`], icon: 'pi pi-video', visible: this.hasModule('streaming') },
          { label: 'Momentos', routerLink: [`/events/${id}/moments`], icon: 'pi pi-images', visible: this.hasModule('moments') },
          { label: 'Cupones', routerLink: [`/events/${id}/coupons`], icon: 'pi pi-images', visible: this.hasModule('coupons') }
        ];
      });

    });

    // FIXME: do it with a secondary outlet
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        const parts = document.location.href.split('/');
        if (parts.length === 6) {
          this.section = parts[parts.length - 1].split('?')[0];
        }
      }
    });
  }

  ngOnInit(): void {
  }

  setHidden(state: boolean): void {
    this.eventService.hide(this.eventId, state).subscribe((response: any) => {
      this.event.hidden = state;
      this.messageService.add({ severity: 'success', summary: 'Evento actualizado correctamente', detail: 'Se ha cambiado la visibilidad correctamente' });
    }, () => {
      this.messageService.add({ severity: 'error', summary: 'Error al actualizar', detail: 'Por favor, revisa los datos e inténtalo de nuevo' });
    })
  }

  getState(state: string) {

    return EventState.getState(state);
  }

  getSeverity(state: string) {

    return EventState.getSeverity(state);
  }

  publish() {
    this.publishing = true;
    this.eventService.publish(this.event.id).subscribe(() => {
      this.messageService.add({ severity: 'success', summary: 'Experiencia publicada correctamente', detail: '' });
      this.publishing = false;
    }, () => {
      this.messageService.add({ severity: 'error', summary: 'Error al actualizar', detail: 'Por favor, revisa los datos e inténtalo de nuevo' });
      this.publishing = false;
    });
  }

  confirm() {
    this.confirmationService.confirm({
      message: 'Esta acción es irreversible, ¿Estás seguro de que quieres borrar la experiencia?',
      accept: () => {
        this.eventService.deleteEvent(this.eventId).subscribe(() => {
          this.router.navigate(['/events']);
        }, () => {
          this.messageService.add({ severity: 'error', summary: 'Error al borrar el experiencia', detail: 'Por favor, inténtalo de nuevo' });
        });
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }

  clone(): void {

    this.confirmationService.confirm({
      message: '¿Seguro/a que quieres duplicar la experiencia?',
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.eventService.clone(this.eventId).subscribe((response: CreatedEvent) => {
          this.messageService.add({ severity: 'success', summary: 'Experiencia duplicado', detail: 'La experiencia se ha duplicado correctamente' });
        });
      }
    });
  }

  copyLink(): void {

    LinkUtils.copy(this.event?.url);
  }

  activate(isActive: boolean): void {

    if (isActive) {
      this.event.state = 'PAUSED';
      this.event.active = false;
      this.eventService.changeStatus(this.eventId, 'PAUSED').subscribe(response => console.log(response));
    } else {
      this.event.state = 'ACTIVE';
      this.event.active = true;
      this.eventService.changeStatus(this.eventId, 'ACTIVE').subscribe(response => console.log(response));
    }

  }

  viewQR(): void {
    this.showQRDiaglog = true;
  }

  notifyFans(): void {
    this.confirmationService.confirm({
      message: 'Vas a notificar a todos tus fans de este evento, ¿estás seguro?',
      accept: () => {
        this.eventService.notifyFans(this.eventId).subscribe(() => {
          this.messageService.add({ severity: 'success', summary: 'Notificación enviada', detail: 'La notificación se ha enviado correctamente' });
          this.event.tenantMembersNotification.sent = true;
        }, () => {
          this.messageService.add({ severity: 'error', summary: 'Error al enviar la notificación', detail: 'Por favor, inténtalo de nuevo más tarde' });
        });
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }

  showServices(): void {
    this.showServicesDialog = true;
  }

  /**
   * Checks if the service is enabled for the given event
   * 
   * @param serviceName the service to check
   * @returns boolean value representing if the service is enabled
   */
  isEnabled(serviceName: string): boolean {

    if (!this.event || !this.event.services) {

      return false;
    }

    return this.event.services.indexOf(serviceName) !== -1;
  }

  enable(service: string): void {

    if (this.isEnabled(service)) {
      this.eventService.disableService(this.eventId, service).subscribe(() => {
        this.event.services.splice(this.event.services.indexOf(service), 1);
      }, () => {
        // TODO: show error
      });

    } else {
      this.eventService.enableService(this.eventId, service).subscribe(() => {
        this.event.services.push(service);
      }, () => {
        // TODO: show error
      });
    }
  }

  private hasModule(module: string): boolean {

    if (!this.event || !this.event.services) {
      return false;
    }

    return this.event.services.indexOf(module) !== -1;
  }

  refreshPage() {
    window.location.reload();
  }

}
