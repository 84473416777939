<div class="container">
  <div class="attendees-table" [class.shrink]="selectedAttendant">
    <p-table
      #attendantsTable
      [value]="attendants"
      selectionMode="single"
      [(selection)]="selectedAttendant"
      (onRowSelect)="openUser($event)"
      responsiveLayout="scroll"
      scrollable="true"
      scrollHeight="flex"
      styleClass="p-datatable-sm"
      [globalFilterFields]="[
        'ticketId',
        'ticketTypeId',
        'email',
        'name',
        'purchasedAt',
        'validated'
      ]"
    >
      <ng-template pTemplate="caption">
        <div class="flex">
          <button
            [disabled]="blockDownload"
            pButton
            pRipple
            type="button"
            label="Exportar listado"
            icon="pi pi-file-excel"
            (click)="exportList()"
          ></button>
          <button
            pButton
            pRipple
            class="ml-3"
            type="button"
            label="Invitar asistentes"
            icon="pi pi-plus"
            (click)="showInviteDialog()"
          ></button>
          <button
            pButton
            label="Borrar filtros"
            class="p-button-outlined ml-3"
            icon="pi pi-filter-slash"
            (click)="clear(attendantsTable)"
          ></button>
          <span class="p-input-icon-left ml-auto">
            <i class="pi pi-search"></i>
            <input
              pInputText
              type="text"
              (input)="
                attendantsTable.filterGlobal($event.target.value, 'contains')
              "
              placeholder="Buscar"
            />
          </span>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th>
            Tipo
            <p-columnFilter
              field="ticketTypeId"
              matchMode="in"
              display="menu"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            >
              <ng-template pTemplate="header">
                <div class="px-3 pt-3 pb-0">
                  <span class="font-bold">Tipo de entrada</span>
                </div>
              </ng-template>
              <ng-template
                pTemplate="filter"
                let-value
                let-filter="filterCallback"
              >
                <p-multiSelect
                  [ngModel]="value"
                  [options]="ticketTypeOptions"
                  placeholder="Any"
                  (onChange)="filter($event.value)"
                  optionLabel="name"
                  optionValue="id"
                >
                  <ng-template let-option pTemplate="item">
                    <div class="inline-block vertical-align-middle">
                      <span class="ml-1 mt-1">{{ option.name }}</span>
                    </div>
                  </ng-template>
                </p-multiSelect>
              </ng-template>
            </p-columnFilter>
          </th>
          <th pSortableColumn="email">
            Email <p-sortIcon field="email"></p-sortIcon>
            <p-columnFilter
              type="text"
              field="email"
              display="menu"
            ></p-columnFilter>
          </th>
          <th pSortableColumn="name">
            Nombre <p-sortIcon field="name"></p-sortIcon>
            <p-columnFilter
              type="text"
              field="name"
              display="menu"
            ></p-columnFilter>
          </th>
          <th pSortableColumn="purchasedAt">
            Fecha <p-sortIcon field="purchasedAt"></p-sortIcon>
            <p-columnFilter
              type="text"
              field="purchasedAt"
              display="menu"
            ></p-columnFilter>
          </th>
          <th pSortableColumn="validated">
            Asistencia
            <p-sortIcon field="validated"></p-sortIcon>
            <p-columnFilter
              field="validated"
              matchMode="in"
              display="menu"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            >
              <ng-template pTemplate="header">
                <div class="px-3 pt-3 pb-0">
                  <span class="font-bold">Validado</span>
                </div>
              </ng-template>
              <ng-template
                pTemplate="filter"
                let-value
                let-filter="filterCallback"
              >
                <p-multiSelect
                  [ngModel]="value"
                  [options]="[
                    { id: true, name: 'Si' },
                    { id: false, name: 'No' }
                  ]"
                  placeholder="Any"
                  (onChange)="filter($event.value)"
                  optionLabel="name"
                  optionValue="id"
                >
                  <ng-template let-option pTemplate="item">
                    <div class="inline-block vertical-align-middle">
                      <span class="ml-1 mt-1">{{ option.name }}</span>
                    </div>
                  </ng-template>
                </p-multiSelect>
              </ng-template>
            </p-columnFilter>
          </th>
          <th></th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-attendant>
        <tr [pSelectableRow]="attendant">
          <td>{{ attendant.ticketTypeName }}</td>
          <td>
            <a [routerLink]="['/users/' + attendant.userId]">{{
              attendant.email
            }}</a>
          </td>
          <td>{{ attendant.fullName }}</td>
          <td>{{ attendant.purchasedAt | date : "short" }}</td>
          <td>
            <div class="flex justify-content-center">
              <i
                *ngIf="attendant.validated === true"
                [pTooltip]="attendant.validatedAt | date : 'dd/MM/yyyy HH:mm'"
                tooltipPosition="top"
                class="pi pi-check"
                style="color: green; font-weight: 600"
              ></i>
              <i
                *ngIf="attendant.validated === false"
                class="pi pi-clock"
                style="color: orange; font-weight: 600"
                pTooltip="Pendiente"
                tooltipPosition="top"
              ></i>
            </div>
          </td>
          <td>
            <button
              *ngIf="attendant.validated === false"
              pButton
              pRipple
              type="button"
              pTooltip="Confirmar"
              tooltipPosition="top"
              class="p-button-text"
              icon="pi pi-check-circle"
              (click)="validate(attendant.ticketId)"
            ></button>
            <button
              *ngIf="attendant.validated === true"
              pButton
              pRipple
              type="button"
              pTooltip="Invalidar"
              tooltipPosition="top"
              class="p-button-text"
              icon="pi pi-undo"
              (click)="unvalidate(attendant.ticketId)"
            ></button>
          </td>
          <td>
            <i class="pi pi-angle-right"></i>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="5">Todavía no hay asistentes.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <div class="detail-panel" *ngIf="selectedAttendant">
    <div class="p-card">
      <div class="flex flex-column">
        <div class="flex flex-row justify-content-end">
          <button
            class="p-button-text mr-3"
            pButton
            pRipple
            icon="pi pi-sync"
            pTooltip="Regenerar token"
            tooltipPosition="bottom"
            type="button"
            (click)="regenerateToken()"
          ></button>
          <button
            class="p-button-text mr-3"
            pButton
            pRipple
            icon="pi pi-send"
            pTooltip="Reenviar token"
            tooltipPosition="bottom"
            type="button"
            (click)="resendToken()"
          ></button>
          <button
            class="p-button-text mr-3"
            pButton
            pRipple
            icon="pi pi-users"
            pTooltip="Reasignar token"
            tooltipPosition="bottom"
            type="button"
            (click)="showAssignDialog()"
          ></button>
          <button
            pButton
            icon="pi pi-times"
            (click)="collapsePanel()"
            class="ml-3 collapse-btn p-button-text"
          ></button>
        </div>
        <div class="flex flex-row justify-content-start">
          <div *ngIf="selectedAttendant; else noAttendant">
            <h3 class="pl-3 attendant-name">
              <ng-container
                *ngIf="selectedAttendant.fullName; else showEmail"
              >
                {{ selectedAttendant.fullName }}
              </ng-container>
              <ng-template #showEmail>
                {{ selectedAttendant.email }}
              </ng-template>
            </h3>
          </div>

          <ng-template #noAttendant>
            <div>Datos del asistente no disponibles.</div>
          </ng-template>
        </div>
      </div>
        
        <div class="p-card-body">
          <p class="b-text">Tipo de Entrada</p>
          <div class="dynamic-content">
            {{ selectedAttendant.ticketTypeName }}
          </div>
          <p class="b-text">Estado de Validación</p>
          <div class="dynamic-content">
            {{ selectedAttendant.validated ? "Validado" : "No Validado" }}
          </div>
          <p class="b-text">Fecha de Compra</p>
          <div class="dynamic-content">
            {{ selectedAttendant.purchasedAt | date : "dd/MM/yyyy HH:mm" }}
          </div>

          <h5 class="additional-experience">Experiencias Adicionales</h5>
          <div>
            <app-complement-experience-list
              type="experience"
              [experiences]="selectedAttendantExperiences"
            ></app-complement-experience-list>
          </div>
          <div class="add-button-container">
            <button
              pButton
              pRipple
              type="button"
              label="Añadir experiencia"
              icon="pi pi-plus"
              (click)="displayAddCustomizationDialog = true"
              class="p-button-text add-button"
            ></button>
          </div>
          <h5 class="additional-complement">Complementos Adicionales</h5>
          <!-- Llamada a app-complement-experience-list  -->
          <app-complement-experience-list
            type="complement"
            [complements]="selectedAttendantSales"
          ></app-complement-experience-list>
          <div class="add-button-container">
            <button
              pButton
              pRipple
              type="button"
              label="Añadir producto"
              icon="pi pi-plus"
              (click)="pressedAssignProduct = true"
              class="p-button-text add-button"
            ></button>
          </div>
          <div class="p-card-footer">
            <button
              pButton
              pRipple
              class="ml-3 p-button-text viewAll-button"
              type="button"
              label="Ver todo"
              (click)="viewAll()"
            ></button>
          </div>
        </div>
      </div>
  </div>

  <p-dialog
    header="Invitar asistentes"
    [modal]="true"
    [(visible)]="showInvite"
    [style]="{ width: '50vw', height: '800px' }"
  >
    <ng-template pTemplate="header">
      <div class="flex flex-column">
        <span class="text-xl font-bold">Invitar asistentes</span>
        <p class="sub">Invita a asistentes de forma directa y sin coste</p>
      </div>
    </ng-template>
    <form [formGroup]="inviteForm">
      <!-- Invitation Type Selector -->
      <div class="p-field-checkbox">
        <p-checkbox
          formControlName="notifyUser"
          binary="true"
          inputId="notifyUser"
          label="Quiero enviar una notificación a los usuarios seleccionados para que sepan que están siendo invitados"
        ></p-checkbox>
      </div>
      <div class="field">
        <label for="invitationType">Tipo de Invitación</label>
        <p-dropdown
          [options]="invitationTypes"
          formControlName="invitationType"
          placeholder="Selecciona un tipo"
          optionLabel="name"
          optionValue="id"
        ></p-dropdown>
      </div>
      <div class="field">
        <label for="name" class="block">Tipo de entrada</label>
        <p-dropdown
          [options]="getFilteredTicketTypes()"
          appendTo="body"
          formControlName="ticketType"
          optionLabel="name"
          optionValue="id"
          placeholder="Selecciona una opción"
        >
          <ng-template let-type pTemplate="item">
            <div class="flex align-items-center gap-2">
              <div>
                <p>{{ type.name }}</p>
                <p class="sub">
                  {{ type.ticketsNumber - type.ticketsSold }} disponibles
                </p>
              </div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
      <!-- Conditional UI based on the selected invitation type -->
      <ng-container
        *ngIf="inviteForm.get('invitationType').value === 'individual'"
      >
        <div class="field">
          <label for="name">Email</label>
          <input
            id="email"
            formControlName="email"
            type="text"
            pInputText
            class="w-full"
          />
        </div>
      </ng-container>
      <ng-container *ngIf="inviteForm.get('invitationType').value === 'bulk'">
        <div>
          <h3>Paso 1: Prepara la subida</h3>
          <p style="color: red">
            Asegúrate que el número de asistentes es menor o igual al número de
            entradas disponibles.
          </p>
          <p>Formatos permitidos: csv</p>
          <p>Cabecera del CSV: name,surname1,surname2,email,phone</p>
          <p>Campos obligatorios: email</p>
          <button
            pButton
            pRipple
            label="Descarga la plantilla CSV"
            icon="pi pi-download"
            type="button"
            class="p-button-text"
            (click)="downloadTemplate()"
          ></button>
        </div>
        <div>
          <h3>Paso 2: Sube tu fichero</h3>
          <div class="field">
            <p-fileUpload
              name="myfile[]"
              mode="basic"
              accept="text/csv"
              chooseLabel="Seleccionar fichero"
              [auto]="true"
              [customUpload]="true"
              (uploadHandler)="myUploader($event)"
            ></p-fileUpload>
            <p *ngIf="file">Archivo seleccionado: {{ file?.name }}</p>
          </div>
        </div>
      </ng-container>
    </form>
    <ng-template pTemplate="footer">
      <p-button
        (click)="cancelInvite()"
        label="Cancelar"
        icon="pi pi-trash"
        styleClass="p-button-text p-button-danger"
      ></p-button>
      <p-button
        (click)="confirmInvite(inviteForm.get('invitationType').value)"
        label="Enviar invitacion"
        icon="pi pi-send"
        styleClass="p-button"
        [disabled]="!inviteForm.valid"
      ></p-button>
    </ng-template>
  </p-dialog>

  <app-add-product-dialog
    [tokenId]="selectedAttendant?.ticketId"
    [displayDialog]="pressedAssignProduct"
    (hideDialog)="hideAddProductDialog()"
  ></app-add-product-dialog>

  <app-customization-dialog
    [tokenId]="selectedAttendant?.ticketId"
    [displayDialog]="displayAddCustomizationDialog"
    (hideDialog)="hideCustomizationDialog()"
  ></app-customization-dialog>

  <p-dialog
    header="Header"
    [(visible)]="displayAssignDialog"
    [modal]="true"
    [style]="{ width: '50vw' }"
  >
    <ng-template pTemplate="header">
      <h2>Reasignar token</h2>
    </ng-template>
    <div class="mb-3">
      <input
        type="email"
        placeholder="Escribe el nuevo email"
        pInputText
        [(ngModel)]="reassignMail"
      />
    </div>
    <div class="flex flex-row align-items-center">
      <p-checkbox
        [(ngModel)]="reassignMailCheck"
        value="New York"
        inputId="ny"
      ></p-checkbox>
      <label class="ml-2" for="ny"
        >Confirmo que quiero reasignar esta entrada</label
      >
    </div>
    <ng-template pTemplate="footer">
      <p-button
        label="Reasignar"
        class="p-button-danger"
        [disabled]="reassignMail === undefined || reassignMailCheck === false"
        (click)="reassignTicket()"
      ></p-button>
    </ng-template>
  </p-dialog>
</div>
