import { Component, OnInit } from '@angular/core';
import { EventService } from './service/event.service';
import { Event } from './model/event.interface';
import { DateTime } from "luxon";
import { Router } from '@angular/router';
import { EventState } from './util/event-state.util';
import { LinkUtils } from './util/link.util';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CreatedEvent } from './model/created-event.interface';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss']
})
export class EventComponent implements OnInit {
  events: Event[] = [];
  futureEvents: Event[] = [];
  pastEvents: Event[] = [];
  selectedEvent: string = '';
  publishing: boolean = false;
  currentView: 'future' | 'past' | 'all' = 'future';

  constructor(
    private eventService: EventService,
    private router: Router,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.refreshEvents();
  }

  private refreshEvents(): void {
    this.eventService.getEvents().subscribe(response => {
      this.events = response;
      this.categorizeEvents();
    });
  }

  private categorizeEvents(): void {
    const currentDate = DateTime.local().startOf('day');

    this.futureEvents = [];
    this.pastEvents = [];

    this.events.forEach(event => {
      const eventDate = DateTime.fromISO(event.startDate).startOf('day');
      if (eventDate >= currentDate) {
        this.futureEvents.push(event);
      } else {
        this.pastEvents.push(event);
      }
    });
  }

  showFutureEvents(): void {
    this.currentView = 'future';
  }

  showPastEvents(): void {
    this.currentView = 'past';
  }

  showAllEvents(): void {
    this.currentView = 'all';
  }

  handleChange(eventId: string, event: any): void {
    event.originalEvent.stopPropagation();

    let newState = 'ACTIVE';
    const index = this.events.findIndex(event => event.id === eventId);

    if (this.events[index].state === 'ACTIVE') {
      newState = 'PAUSED';
      this.events[index].active = false;
    } else {
      newState = 'ACTIVE';
      this.events[index].active = true;
    }

    this.events[index].state = newState;
    this.eventService.changeStatus(eventId, newState).subscribe(response => console.log(response));
  }

  getState(state: string): string {
    return EventState.getState(state);
  }

  getSeverity(state: string): string {
    return EventState.getSeverity(state);
  }

  openEvent($event): void {
    this.router.navigate(['/events', $event.data.id]);
  }

  copyLink(url: string): void {
    LinkUtils.copy(url);
  }

  publish(eventId: string): void {
    this.publishing = true;
    this.eventService.publish(eventId).subscribe(() => {
      this.messageService.add({ severity: 'success', summary: 'Experiencia publicada correctamente', detail: '' });
      const index = this.events.findIndex(event => event.id === eventId);
      if (index > -1) {
        this.events[index].state = 'ACTIVE';
        this.events[index].active = true;
      }
      this.publishing = false;
    }, () => {
      this.messageService.add({ severity: 'error', summary: 'Error al publicar', detail: 'Por favor, revisa los datos e inténtalo de nuevo' });
      this.publishing = false;
    });
  }

  getLocalDate(eventDate: string): string {
    return DateTime.fromISO(eventDate).toLocaleString(DateTime.DATETIME_SHORT);
  }

  formatAddress(event: any): string {
    if (event === undefined) return '';

    if (event?.location?.name && event?.location?.addressComponents.length > 0) {
      const locality = event?.location.addressComponents.find(x => x.types.includes('locality'));
      return `${event?.location.name}, ${locality?.short_name ? locality?.short_name : ''}`;
    } else {
      return event?.location?.formattedAddress || '';
    }
  }

  clone(id: string): void {
    this.confirmationService.confirm({
      message: '¿Seguro/a que quieres duplicar la experiencia?',
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.eventService.clone(id).subscribe((response: CreatedEvent) => {
          this.messageService.add({ severity: 'success', summary: 'Experiencia duplicada', detail: 'La experiencia se ha duplicado correctamente' });
          this.refreshEvents();
        });
      }
    });
  }
}
