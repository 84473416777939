<p-toast></p-toast>
<p-confirmDialog [style]="{ width: '50vw' }"></p-confirmDialog>
<div class="card h-full">
  <div class="card-container justify-content-start p-3">
    <div class="block border-round mb-3">
      <div class="actions-container" id="actions">
        <button (click)="showFutureEvents()" class="future-events-button"
          [ngClass]="{ active: currentView === 'future' }" type="button">
          Futuras
        </button>
        <button (click)="showPastEvents()" class="past-events-button" [ngClass]="{ active: currentView === 'past' }"
          type="button">
          Pasadas
        </button>
        <button (click)="showAllEvents()" class="all-events-button" [ngClass]="{ active: currentView === 'all' }"
          type="button">
          Todas
        </button>
      </div>
    </div>

    <div class="block border-round mb-3">
      <p-table *ngIf="currentView === 'future'" [value]="futureEvents" selectionMode="single"
        [(selection)]="selectedEvent" (onRowSelect)="openEvent($event)">
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="name">
              Nombre <p-sortIcon field="name"></p-sortIcon>
            </th>
            <th pSortableColumn="location">
              Localización <p-sortIcon field="location"></p-sortIcon>
            </th>
            <th>Tokens vendidos / totales</th>
            <th pSortableColumn="startDate">
              Fecha <p-sortIcon field="startDate"></p-sortIcon>
            </th>
            <th pSortableColumn="state">
              Estado <p-sortIcon field="state"></p-sortIcon>
            </th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template let-event pTemplate="body">
          <tr [pSelectableRow]="event">
            <td>{{ event.name }}</td>
            <td>{{ formatAddress(event) }}</td>
            <td>
              <div class="flex flex-row align-items-center">
                <p *ngIf="event.ticketsNumber">
                  {{ event.ticketsSold }} / {{ event.ticketsNumber }}
                </p>
                <button pButton icon="pi pi-external-link" pTooltip="Ver asistentes"
                  [routerLink]="[event.id, 'attendees']" class="p-button-text row-button"></button>
              </div>
            </td>
            <td>
              <p *ngIf="event.startDate">{{ getLocalDate(event.startDate) }}</p>
            </td>
            <td>
              <p-tag [value]="getState(event.state)"
                [ngClass]="event.state === 'ACTIVE' ? 'active' : 'not-active'"></p-tag>
            </td>
            <td>
              <p-inputSwitch *ngIf="event.state !== 'DRAFT'" (onChange)="handleChange(event.id, $event)"
                [(ngModel)]="event.active"></p-inputSwitch>
            </td>
            <td>
              <div class="flex" *ngIf="event.state !== 'DRAFT'">
                <button pButton pRipple type="button" icon="pi pi-link" pTooltip="Copiar enlace" tooltipPosition="top"
                  class="p-button-rounded p-button-text" (click)="copyLink(event.url)"></button>
                <button pButton pRipple type="button" icon="pi pi-clone" pTooltip="Clonar experiencia"
                  tooltipPosition="top" class="p-button-rounded p-button-text" (click)="clone(event.id)"></button>
              </div>
              <div class="flex justify-content-center" *ngIf="event.state === 'DRAFT'">
                <button pButton class="p-button-text p-button-success" type="button" label="Publicar"
                  (click)="publish(event.id)"></button>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>

      <p-table *ngIf="currentView === 'past'" [value]="pastEvents" selectionMode="single" [(selection)]="selectedEvent"
        (onRowSelect)="openEvent($event)">
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="name">
              Nombre <p-sortIcon field="name"></p-sortIcon>
            </th>
            <th pSortableColumn="location">
              Localización <p-sortIcon field="location"></p-sortIcon>
            </th>
            <th>Tokens vendidos / totales</th>
            <th pSortableColumn="startDate">
              Fecha <p-sortIcon field="startDate"></p-sortIcon>
            </th>
            <th pSortableColumn="state">
              Estado <p-sortIcon field="state"></p-sortIcon>
            </th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template let-event pTemplate="body">
          <tr [pSelectableRow]="event">
            <td>{{ event.name }}</td>
            <td>{{ formatAddress(event) }}</td>
            <td>
              <div class="flex flex-column align-items-start">
                <p *ngIf="event.ticketsNumber">
                  {{ event.ticketsSold }} / {{ event.ticketsNumber }}
                </p>
                <button pButton label="Ver asistentes" [routerLink]="[event.id, 'attendees']"
                  class="p-button-text row-button"></button>
              </div>
            </td>
            <td>
              <p *ngIf="event.startDate">{{ getLocalDate(event.startDate) }}</p>
            </td>
            <td>
              <p-tag [value]="getState(event.state)"
                [ngClass]="event.state === 'ACTIVE' ? 'active' : 'not-active'"></p-tag>
            </td>
            <td>
              <p-inputSwitch *ngIf="event.state !== 'DRAFT'" (onChange)="handleChange(event.id, $event)"
                [(ngModel)]="event.active"></p-inputSwitch>
            </td>
            <td>
              <div class="flex" *ngIf="event.state !== 'DRAFT'">
                <button pButton pRipple type="button" icon="pi pi-link" pTooltip="Copiar enlace" tooltipPosition="top"
                  class="p-button-rounded p-button-text" (click)="copyLink(event.url)"></button>
                <button pButton pRipple type="button" icon="pi pi-clone" pTooltip="Clonar experiencia"
                  tooltipPosition="top" class="p-button-rounded p-button-text" (click)="clone(event.id)"></button>
              </div>
              <div class="flex justify-content-center" *ngIf="event.state === 'DRAFT'">
                <button pButton class="p-button-text p-button-success" type="button" label="Publicar"
                  (click)="publish(event.id)"></button>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>

      <p-table *ngIf="currentView === 'all'" [value]="events" selectionMode="single" [(selection)]="selectedEvent"
        (onRowSelect)="openEvent($event)">
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="name">
              Nombre <p-sortIcon field="name"></p-sortIcon>
            </th>
            <th pSortableColumn="location">
              Localización <p-sortIcon field="location"></p-sortIcon>
            </th>
            <th>Tokens vendidos / totales</th>
            <th pSortableColumn="startDate">
              Fecha <p-sortIcon field="startDate"></p-sortIcon>
            </th>
            <th pSortableColumn="state">
              Estado <p-sortIcon field="state"></p-sortIcon>
            </th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template let-event pTemplate="body">
          <tr [pSelectableRow]="event">
            <td>{{ event.name }}</td>
            <td>{{ formatAddress(event) }}</td>
            <td>
              <div class="flex flex-column align-items-start">
                <p *ngIf="event.ticketsNumber">
                  {{ event.ticketsSold }} / {{ event.ticketsNumber }}
                </p>
                <button pButton label="Ver asistentes" [routerLink]="[event.id, 'attendees']"
                  class="p-button-text row-button"></button>
              </div>
            </td>
            <td>
              <p *ngIf="event.startDate">{{ getLocalDate(event.startDate) }}</p>
            </td>
            <td>
              <p-tag [value]="getState(event.state)"
                [ngClass]="event.state === 'ACTIVE' ? 'active' : 'not-active'"></p-tag>
            </td>
            <td>
              <p-inputSwitch *ngIf="event.state !== 'DRAFT'" (onChange)="handleChange(event.id, $event)"
                [(ngModel)]="event.active"></p-inputSwitch>
            </td>
            <td>
              <div class="flex" *ngIf="event.state !== 'DRAFT'">
                <button pButton pRipple type="button" icon="pi pi-link" pTooltip="Copiar enlace" tooltipPosition="top"
                  class="p-button-rounded p-button-text" (click)="copyLink(event.url)"></button>
                <button pButton pRipple type="button" icon="pi pi-clone" pTooltip="Clonar experiencia"
                  tooltipPosition="top" class="p-button-rounded p-button-text" (click)="clone(event.id)"></button>
              </div>
              <div class="flex justify-content-center" *ngIf="event.state === 'DRAFT'">
                <button pButton class="p-button-text p-button-success" type="button" label="Publicar"
                  (click)="publish(event.id)"></button>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
  <button [routerLink]="['/create']" class="floating-button" pButton pRipple type="button">
    <i class="pi pi-plus"></i>
  </button>
</div>