import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Attendant } from '../model/attendant.interface';
import { CreatedEvent } from '../model/created-event.interface';
import { CreatedEntity } from '../model/created-entity.interface';
import { EventExperience, EventResponse, EventSponsor, EventTickets } from '../model/event-detail.interface';
import { Event } from '../model/event.interface'
import { SignedURL } from '../model/signed-url.interface';
import { Sale } from '../model/sale.interface';
import { TicketCreate } from '../model/ticket-create.interface';
import { ExperienceCreate } from '../model/experience-create.interface';
import { EventInvite } from '../model/event-invite.interface';
import { Message } from '../model/message.interface';
import { EventSummary } from '../model/event-summary.interface';
import { TicketsByDay } from '../model/tickets-by-day.interface';
import { ProductsByDay } from '../model/products-by-day.interface';
import { ExperiencesById } from '../model/experiences-by-id.interface';
import { ExportResponse } from 'src/app/shared/model/export-response.interface';
import { EventStreaming } from '../model/event-streaming.inferface';
import { TicketPosition } from '../model/ticket-position.interface';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  baseUrl: string = environment.apiUrl

  private paymentComplete = new Subject<any>();

  paymentComplete$ = this.paymentComplete.asObservable();

  constructor(private http: HttpClient) {
  }

  getEvents(): Observable<Event[]> {

    const url = `${this.baseUrl}/events`

    return this.http.get<Event[]>(url);

  }

  validateAttendant(ticketId: string): Observable<any> {

    const url = `${this.baseUrl}/tickets/${ticketId}/validate`;

    return this.http.get<any>(url);
  }

  unvalidateAttendant(ticketId: string): Observable<any> {

    const url = `${this.baseUrl}/tickets/${ticketId}/validate`;

    return this.http.delete<any>(url);
  }

  deliverMerch(eventId: string, purchaseId: string, productId: string) {

    const url = `${this.baseUrl}/events/${eventId}/merch/${purchaseId}/${productId}/validate`;

    return this.http.get<any>(url);
  }

  undeliverMerch(eventId: string, purchaseId: string, productId: string) {

    const url = `${this.baseUrl}/events/${eventId}/merch/${purchaseId}/${productId}/validate`;

    return this.http.delete<any>(url);
  }

  getEventById(eventId: string): Observable<EventResponse> {

    const url = `${this.baseUrl}/events/${eventId}`;

    return this.http.get<EventResponse>(url);

  }

  createEvent(data: any): Observable<CreatedEvent> {

    const url = `${this.baseUrl}/events`;

    return this.http.post<CreatedEvent>(url, data);

  }

  createDraft(data: any): Observable<CreatedEvent> {

    const url = `${this.baseUrl}/events/draft`;

    return this.http.post<CreatedEvent>(url, data);
  }

  publish(eventId: string): Observable<CreatedEvent> {

    const url = `${this.baseUrl}/events/${eventId}/publish`;

    return this.http.post<CreatedEvent>(url, {});
  }

  enableService(eventId: string, service: string) {

    const url = `${this.baseUrl}/events/${eventId}/services?service=${service}`;

    return this.http.post<void>(url, {});
  }

  disableService(eventId: string, service: string) {

    const url = `${this.baseUrl}/events/${eventId}/services?service=${service}`;

    return this.http.delete<void>(url, {});
  }



  clone(eventId: string) {
    const url = `${this.baseUrl}/events/${eventId}/clone`;

    return this.http.post<CreatedEvent>(url, {});
  }

  code(eventId: string): Observable<CreatedEntity> {

    const url = `${this.baseUrl}/events/${eventId}/code`;

    return this.http.get<CreatedEntity>(url);
  }

  hide(eventId: string, state: boolean): Observable<CreatedEntity> {

    const url = `${this.baseUrl}/events/${eventId}/hide?hidden=${state}`;

    return this.http.put<any>(url, undefined);
  }

  updateEvent(eventId: string, data: any, part: string): Observable<CreatedEvent> {

    const url = `${this.baseUrl}/events/${eventId}/${part}`

    let body = {};

    if (part === 'description') {
      body = data.description;
    }
    if (part === 'venue') {
      body = data.venue;
    }
    if (part === 'tickets') {
      body = data.tickets;
    }

    return this.http.put<CreatedEvent>(url, body);
  }

  retrieveAttendants(eventId: string): Observable<Attendant[]> {

    const url = `${this.baseUrl}/events/${eventId}/attendants`;

    return this.http.get<Attendant[]>(url);

  }

  retrieveSales(eventId: string): Observable<Sale[]> {

    const url = `${this.baseUrl}/events/${eventId}/sales`;

    return this.http.get<Sale[]>(url);

  }

  retrieveTicketTypes(eventId: string): Observable<any[]> {
    const url = `${this.baseUrl}/events/${eventId}/tickets/types`;

    return this.http.get<any[]>(url);
  }

  changeStatus(eventId: string, state: string) {

    const url = `${this.baseUrl}/events/${eventId}/state/${state}`

    return this.http.put(url, undefined);
  }

  deleteEvent(eventId: string): Observable<void> {

    const url = `${this.baseUrl}/events/${eventId}`

    return this.http.delete<void>(url);
  }

  getSignedUrl(imageId: string, mimeType: string): Observable<SignedURL> {

    const url = `${this.baseUrl}/events/${imageId}/upload`

    return this.http.get<SignedURL>(url, { params: { mimeType } });
  }

  uploadImage(fileuploadurl, contenttype, file): Observable<any> {

    //this.http = new HttpClient(this.handler); // to reset the header

    const headers = new HttpHeaders({ 'Content-Type': contenttype });
    const req = new HttpRequest(
      'PUT',
      fileuploadurl,
      file,
      {
        headers: headers,
        reportProgress: true, //This is required for track upload process
      });
    return this.http.request(req);
  }

  getExperiences(eventId: string) {

    let url = `${this.baseUrl}/events/${eventId}/experiences`;

    return this.http.get<EventExperience[]>(url);
  }

  createExperience(eventId: string, data: ExperienceCreate): Observable<CreatedEntity> {

    let url = `${this.baseUrl}/events/${eventId}/experiences`;

    return this.http.post<CreatedEntity>(url, data);
  }

  updateExperience(eventId: string, experienceId: string, data: ExperienceCreate): Observable<void> {

    let url = `${this.baseUrl}/events/${eventId}/experiences/${experienceId}`;

    return this.http.put<void>(url, data);
  }

  deleteExperience(eventId: string, experienceId: string): Observable<void> {

    let url = `${this.baseUrl}/events/${eventId}/experiences/${experienceId}`;

    return this.http.delete<void>(url);
  }

  deleteTicket(eventId: string, ticketId: string): Observable<void> {

    let url = `${this.baseUrl}/events/${eventId}/tickets/${ticketId}`;

    return this.http.delete<void>(url);
  }

  getTickets(eventId: any) {

    let url = `${this.baseUrl}/events/${eventId}/tickets/types`;

    return this.http.get<EventTickets[]>(url);
  }

  addTicket(eventId: string, data: TicketCreate): Observable<CreatedEntity> {

    let url = `${this.baseUrl}/events/${eventId}/tickets`;

    return this.http.post<CreatedEntity>(url, data);
  }

  updateTicket(eventId: string, ticketId: string, data: TicketCreate): Observable<void> {

    let url = `${this.baseUrl}/events/${eventId}/tickets/${ticketId}`;

    return this.http.put<void>(url, data);
  }

  /**
   * Gerates new tokens for an existing token type
   * @param eventId the event id the token belongs to
   * @param ticketId the token id the tickets are going to be generated
   * @param data the tokens number
   * @returns void
   */
  addTickets(eventId: string, ticketId: string, data: { number: number; }): Observable<void> {

    let url = `${this.baseUrl}/events/${eventId}/tickets/${ticketId}/add`;

    return this.http.post<void>(url, data);
  }

  changeTicketStatus(eventId: string, ticketId: string, state: boolean): Observable<void> {

    let url = `${this.baseUrl}/events/${eventId}/tickets/${ticketId}/state/${state}`;

    return this.http.put<void>(url, {});
  }

  createSponsor(eventId: string, data: EventSponsor): Observable<CreatedEntity> {

    let url = `${this.baseUrl}/events/${eventId}/sponsors`;

    return this.http.post<CreatedEntity>(url, data);
  }

  deleteSponsor(eventId: string, sponsorId: string): Observable<void> {

    let url = `${this.baseUrl}/events/${eventId}/sponsors/${sponsorId}`;

    return this.http.delete<void>(url);
  }

  getMessages(eventId: string): Observable<Message[]> {

    let url = `${this.baseUrl}/events/${eventId}/messages`;

    return this.http.get<Message[]>(url);
  }

  postMessage(eventId: string, message: any): Observable<void> {

    let url = `${this.baseUrl}/events/${eventId}/messages`;

    return this.http.post<void>(url, message);
  }

  deleteMessage(eventId: string, messageId: string): Observable<void> {

    let url = `${this.baseUrl}/events/${eventId}/messages/${messageId}`;

    return this.http.delete<void>(url);
  }

  inviteUser(eventId: string, data: EventInvite): Observable<CreatedEntity> {

    let url = `${this.baseUrl}/events/${eventId}/attendants/invite`;

    const body = {
      email: data.email,
      cartCreation: {
        eventId: eventId,
        items: [
          {
            id: data.ticketType,
            type: 'ticket',
            quantity: 1,
            upsell: []
          }
        ]
      },
      notifyUser: data.notifyUser === true
    };

    return this.http.post<CreatedEntity>(url, body);
  }

  getEventSummary(eventId: string) {

    let url = `${this.baseUrl}/analytics/${eventId}`;

    return this.http.get<EventSummary>(url);
  }

  getEventSummaryTickets(eventId: any): Observable<TicketsByDay[]> {

    let url = `${this.baseUrl}/analytics/${eventId}/tickets`;

    return this.http.get<TicketsByDay[]>(url);
  }

  getEventSummaryProducts(eventId: any): Observable<ProductsByDay[]> {

    let url = `${this.baseUrl}/analytics/${eventId}/products`;

    return this.http.get<ProductsByDay[]>(url);
  }

  getEventSummaryExperiences(eventId: any): Observable<ExperiencesById[]> {

    let url = `${this.baseUrl}/analytics/${eventId}/experiences`;

    return this.http.get<ExperiencesById[]>(url);
  }

  export(eventId: string): Observable<ExportResponse> {

    let url = `${this.baseUrl}/export/event/${eventId}/attendants`;

    return this.http.get<ExportResponse>(url);
  }

  notifyFans(eventId: string): Observable<void> {

    let url = `${this.baseUrl}/events/${eventId}/notify`;

    return this.http.post<void>(url, {});
  }

  updateEventStreaming(eventId: string, streaming: EventStreaming): Observable<any> {

    let url = `${this.baseUrl}/events/${eventId}/streaming`;

    return this.http.put<void>(url, streaming);
  }

  getServices(eventId: string): Observable<String[]> {
    let url = `${this.baseUrl}/events/${eventId}/services`;

    return this.http.get<String[]>(url);
  }

  sendMessage(campaignType: string, eventId: string, referenceId: string, message: string, redirectToSuffix: string) {

    let url = `${this.baseUrl}/events/${eventId}/`;

    if (campaignType === 'ticketType') {

      url += `tickets/types/${referenceId}/message`;
    } else {
      
      url += `experiences/${referenceId}/message`;
    }

    return this.http.post<void>(url, { message, redirectToSuffix });
  }

  updateTicketPositions(eventId: string, ticketPositions: TicketPosition[]): Observable<void> {
    return this.http.put<void>(`${this.baseUrl}/events/${eventId}/tickets/positions`, ticketPositions);
  }

}
