import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { CreatedEvent } from '../../model/created-event.interface';
import { FormPart } from '../../model/form-part.interface';
import { EventService } from '../../service/event.service';

@Component({
  selector: 'app-create-event',
  templateUrl: './create-event.component.html',
  styleUrls: ['./create-event.component.scss']
})
export class CreateEventComponent implements OnInit {

  newEvent: any;
  startDate: Date;
  activeStep = 0;
  success = false;
  eventId: string = undefined;
  createdEvent: CreatedEvent;
  loading = false;

  data = new FormGroup({
  });

  formValue: any;

  items = [{
    label: 'Descripción'
  },
  {
    label: 'Celebración'
  },
  {
    label: 'Entradas'
  },
  {
    label: 'Revisión'
  }
  ];

  constructor(private eventService: EventService, private activatedRoute: ActivatedRoute, private router: Router, private messageService: MessageService) { }

  ngOnInit(): void {
    const id = this.activatedRoute.params.subscribe((params) => {
      if (params['id']) {
        this.eventId = params['id'];
        this.eventService.getEventById(params['id']).subscribe((data: any) => {
          this.formValue = data;
        });
      }
    });
  }

  onNext($event: FormPart) {
    this.activeStep += $event.next;
    if ($event.name !== undefined && $event.data !== undefined) {
      this.data.setControl($event.name, $event.data);
      this.formValue = this.data;
      if ($event.name === 'venue') {
        this.startDate = new Date($event.data.value.startDate);
      }
    }
  }

  onSave($event: FormPart) {
    // Update the form part
    // FIXME: extract common logic
    if ($event.name !== undefined && $event.data !== undefined) {
      this.data.setControl($event.name, $event.data);
      if ($event.name === 'venue') {
        this.startDate = new Date($event.data.value.startDate);
      }
    }

    this.eventService.createDraft(this.data.value).subscribe((response: CreatedEvent) => {
      this.messageService.add({ severity: 'success', summary: 'Borrador guardado', detail: '' });
      this.router.navigate(['/events', response.id]);
    });

  }

  onComplete($event: boolean) {
    this.loading = true;
    if ($event) {
      if (this.eventId !== undefined) {
        this.eventService.updateEvent(this.eventId, this.data.value, '').subscribe((response: CreatedEvent) => {
          this.loading = false;
          if (response.url !== undefined) {
            this.messageService.add({ severity: 'success', summary: 'Experiencia actualizada', detail: '' });
            this.success = true;
            this.createdEvent = response;
          }
        }, () => {
          this.messageService.add({ severity: 'error', summary: 'Ha ocurrido un error', detail: 'Revisa los datos y vuelve a intentarlo' });
          this.loading = false;
        });

      } else {
        this.eventService.createEvent(this.data.value).subscribe((response: CreatedEvent) => {
          this.loading = false;
          this.createdEvent = response;
          if (response.url !== undefined) {
            this.messageService.add({ severity: 'success', summary: 'Experiencia creada', detail: '' });
            this.success = true;
            this.createdEvent = response;
          }
        }, () => {
          this.messageService.add({ severity: 'error', summary: 'Ha ocurrido un error', detail: 'Revisa los datos y vuelve a intentarlo' });
          this.loading = false;
        });
      }
    }
  }

}
