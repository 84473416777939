<p-toast position="top-right"></p-toast>
<div class="card h-full">
  <div class="card-container justify-content-start border-round p-3">
    <div class="flex flex-row align-items-center justify-content-between mb-3">
      <div class="flex-grow-1">
        <h2>{{ fullName }}</h2>
      </div>
      <div class="flex flex-row align-items-center">
        <!-- Actions row -->
      </div>
    </div>

    <div class="flex flex-row">
      <!-- The secondary menu -->
      <div>
        <p-menu [model]="items"></p-menu>
      </div>
      <!-- The content -->
      <div class="content ml-3">
        <!-- Section summary -->
        <div *ngIf="!section || section === 'summary'">
          <p-card>
            <ng-template pTemplate="title">Ciclo de vida</ng-template>
            <ng-template pTemplate="subtitle">Resumen de fidelización del fan</ng-template>
            <div class="flex align-items-start justify-content-start align-items-center analytics-row">
              <div #chartTotalTokens class="box-25"></div>
              <div #chartTotalPurchases class="box-25"></div>
              <div #chartTotalPurchased class="box-25"></div>
              <div #chartTotalUpselling class="box-25"></div>
            </div>
          </p-card>
        </div>
        <!-- Section data -->
        <div *ngIf="section === 'profile'">
          <p-card>
            <ng-template pTemplate="title">Datos del fan</ng-template>
            <ng-template pTemplate="subtitle">Datos personales y estado del usuario</ng-template>
            <div class="flex flex-column">
              <div class="flex flex-row align-items-center">
                <h4>Nombre:&nbsp;</h4>
                <p>{{ getFullName() }}</p>
              </div>
              <div class="flex flex-row align-items-center">
                <div>
                  <h4>Correo electrónico:&nbsp;</h4>
                </div>
                <div>
                  <p>
                    <a [href]="'mailto:' + user?.email">{{ user?.email }}</a>
                  </p>
                </div>
                <div *ngIf="user?.email">
                  <p-button icon="pi pi-copy" styleClass="p-button-rounded p-button-text"
                    (click)="copyToClipboard(user?.email)"></p-button>
                </div>
              </div>
              <div class="flex flex-row align-items-center">
                <div>
                  <h4>Teléfono:&nbsp;</h4>
                </div>
                <div>
                  <a [href]="'tel:' + user?.phone">{{ user?.phone }}</a>
                </div>
                <div *ngIf="user?.phone">
                  <p-button icon="pi pi-copy" styleClass="p-button-rounded p-button-text"
                    (click)="copyToClipboard(user?.phone)"></p-button>
                </div>
              </div>
              <div class="flex flex-row align-items-center">
                <h4>Estado:&nbsp;</h4>
                <p><p-tag [value]="getState(user?.state)"></p-tag></p>
              </div>
              <div class="flex flex-row align-items-center">
                <h4>Identificación fiscal:&nbsp;</h4>
                <p>{{ user?.fiscalId }}</p>
              </div>
              <div class="flex flex-row align-items-center">
                <h4>Fecha de nacimiento:&nbsp;</h4>
                <p>{{ user?.birthDate | date : "dd/MM/yyyy" }}</p>
              </div>
              <div class="flex flex-row align-items-center">
                <h4>Género:&nbsp;</h4>
                <p>{{ user?.genre }}</p>
              </div>
            </div>
          </p-card>
        </div>
        <!-- Section public profile -->
        <div *ngIf="section === 'public'">
          <app-public-profile [user]="user"></app-public-profile>
        </div>
        <!-- Section tier -->
        <div *ngIf="section === 'tier'">
          <p-card>
            <ng-template pTemplate="title">Etiquetas</ng-template>
            <ng-template pTemplate="subtitle">Añade las etiquetas que quieras al usuario</ng-template>
            <div class="flex flex-column">
              <app-label-input [labels]="user?.labels" (onLabelAdd)="onLabelAdd($event)"
                (onLabelRemove)="onLabelRemove($event)"></app-label-input>
            </div>
          </p-card>
        </div>
        <!-- Section tokens -->
        <div *ngIf="section === 'tokens'">
          <app-token-history [userId]="user?.id"></app-token-history>
        </div>
        <!-- Section purchases -->
        <div *ngIf="section === 'purchases'">
          <app-user-history [purchases]="purchases$"></app-user-history>
        </div>
      </div>
    </div>
  </div>
</div>