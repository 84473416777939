import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { UserComponent } from './user.component';
import { UserDetailComponent } from './components/user-detail/user-detail.component';
import { IsConfiguredGuard } from '../auth/guard/is-configured.guard';
import { PurchaseDetailComponent } from './components/purchase-detail/purchase-detail.component';
import { TokenDetailComponent } from './components/token-detail/token-detail.component';

const routes: Routes = [
  {
    path: '', component: UserComponent, canActivate: [IsConfiguredGuard]
  },
  {
    path: ':id', component: UserDetailComponent, canActivate: [IsConfiguredGuard], children: [
      {
        path: 'summary', component: UserDetailComponent
      },
      {
        path: 'profile', component: UserDetailComponent
      },
      {
        path: 'public', component: UserDetailComponent
      },
      {
        path: 'tier', component: UserDetailComponent
      },
      {
        path: 'tokens', component: UserDetailComponent
      },
      {
        path: 'purchases', component: UserDetailComponent
      },
    ]
  },
  {
    path: ':id/purchases/:purchaseId', component: PurchaseDetailComponent, canActivate: [IsConfiguredGuard]
  },
  {
    path: ':id/tokens/:tokenId', component: TokenDetailComponent, canActivate: [IsConfiguredGuard], children: [
      {
        path: 'summary', component: TokenDetailComponent
      },
      {
        path: 'assignation', component: TokenDetailComponent
      },
      {
        path: 'experiences', component: TokenDetailComponent
      },
      {
        path: 'products', component: TokenDetailComponent
      },
      {
        path: 'wallet', component: TokenDetailComponent
      },
      {
        path: 'messages', component: TokenDetailComponent
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserRoutingModule {
}
