<p-card>
  <ng-template pTemplate="title">Perfil público</ng-template>
  <ng-template pTemplate="subtitle"
    >Datos públicos del usuario</ng-template
  >
  <div class="flex flex-column">
    <div class="flex flex-row align-items-center">
      <div>
        <h4>Headline:&nbsp;</h4>
      </div>
      <div>
        <p>{{ user?.headline }}</p>
      </div>
    </div>
    <div class="flex flex-row align-items-center">
      <div>
        <h4>Bio:&nbsp;</h4>
      </div>
      <div>
        <p>{{ user?.description }}</p>
      </div>
    </div>
    <div class="flex flex-row align-items-center">
      <div>
        <h4>LinkedIn:&nbsp;</h4>
      </div>
      <div>
        <p>{{ user?.linkedin }}</p>
      </div>
    </div>
    <div class="flex flex-row align-items-center justify-content-start">
      <div>
        <h4>Twitter:&nbsp;</h4>
      </div>
      <div>
        <p>{{ user?.twitter }}</p>
      </div>
    </div>
    <div class="flex flex-row align-items-center">
      <h4>Instagram:&nbsp;</h4>
      <p>{{ user?.instagram }}</p>
    </div>
  </div>
</p-card>
