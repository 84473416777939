import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Attendant } from '../../model/attendant.interface';
import { EventService } from '../../service/event.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { ExportResponse } from 'src/app/shared/model/export-response.interface';
import { ContentUpload } from 'src/app/shared/model/content-upload.interface';
import { UserService } from 'src/app/user/service/user.service';
import { JobStatus } from 'src/app/user/model/job-status.interface';
import { HttpErrorResponse } from '@angular/common/http';
import { Sale } from 'src/app/event/model/sale.interface';
import { DeliveryService } from 'src/app/shared/services/delivery.service';
import { AdditionalExperience } from 'src/app/user/model/ticket-detail.interface';

@Component({
  selector: 'app-event-detail-attendees',
  templateUrl: './event-detail-attendees.component.html',
  styleUrls: ['./event-detail-attendees.component.scss'],
})
export class EventDetailAttendeesComponent implements OnInit, OnChanges {
  @Input() eventId: string = '';
  selectedAttendant: Attendant;
  attendants: Attendant[] = [];
  ticketTypeOptions: any[] = [];
  blockDownload: boolean = false;
  loading: boolean;
  file: File;
  uploadJob: ContentUpload;
  intervalId: any;
  jobStatus: JobStatus;
  errorMessage: string;

  showInvite: boolean = false;
  pressedAssignProduct: boolean = false;
  displayAddCustomizationDialog: boolean = false;
  showToken: boolean = false;
  showErrorQR: boolean = false;
  fallbackQR: string = '';
  tokenUrls: { [key: string]: string };
  displayAssignDialog: boolean = false;
  reassignMail: string;
  reassignMailCheck: boolean = false;
  selectedAttendantSales: Sale[] = [];
  selectedAttendantExperiences: AdditionalExperience[] = [];

  invitationTypes = [
    { id: 'individual', name: 'Individual' },
    { id: 'bulk', name: 'Masiva' },
  ];

  inviteForm: FormGroup = new FormGroup({
    invitationType: new FormControl('individual', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    ticketType: new FormControl('', [Validators.required]),
    notifyUser: new FormControl(false)
  });
  token: any;

  constructor(
    private eventService: EventService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private deliveryService: DeliveryService
  ) {}

  ngOnInit(): void {

    this.inviteForm.get('invitationType').valueChanges.subscribe((type) => {
      this.updateFormFields(type);
    });

    this.updateFormFields(this.inviteForm.get('invitationType').value);

    this.activatedRoute.queryParams.subscribe((params) => {
      const eventId = params['eventId'];
      if (eventId) {
        this.eventId = eventId;
        this.loadEventDetails();
      }
    });

  }

  loadEventDetails(): void {
    this.eventService.retrieveAttendants(this.eventId).subscribe(
      (response: Attendant[]) => {
        this.attendants = response;

        this.activatedRoute.queryParams.subscribe((params) => {
          const attendantTicketId = params['id'];

          if (attendantTicketId) {
            this.findAndSelectAttendant(attendantTicketId);
          } else {
            this.selectedAttendant = null;
          }
        });
      },
      (error) => {
        console.error('Error loading attendants:', error);
      }
    );

    this.eventService.retrieveTicketTypes(this.eventId).subscribe(
      (response: any[]) => {
        this.ticketTypeOptions = response;
      },
      (error) => {
        console.error('Error loading ticket types:', error);
      }
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['eventId'] && changes['eventId'].currentValue) {
      this.loadEventDetails();
    }
  }

  findAndSelectAttendant(attendantTicketId: string): void {
    const attendant = this.attendants.find((a) => a.ticketId === attendantTicketId);

    if (attendant) {
      this.selectedAttendant = attendant;
      this.openUser(this.selectedAttendant);
    } else {
      console.warn('Attendant not found');
    }
  }

  updateFormFields(type: string) {
    this.inviteForm.addControl(
      'ticketType',
      new FormControl('', [Validators.required])
    );
    if (type === 'individual') {
      this.inviteForm.addControl(
        'email',
        new FormControl('', [Validators.required, Validators.email])
      );
      this.inviteForm.removeControl('file');
    } else if (type === 'bulk') {
      this.inviteForm.removeControl('email');
      this.inviteForm.addControl(
        'file',
        new FormControl(null, [Validators.required])
      );
    }
  }

  validate(ticketId: string) {

    this.eventService.validateAttendant(ticketId).subscribe(() => {
      const attendantIndex = this.attendants.findIndex(a => a.ticketId === ticketId);

      // Replace attendant
      this.attendants[attendantIndex] = {
        ticketId: this.attendants[attendantIndex].ticketId,
        ticketTypeId: this.attendants[attendantIndex].ticketTypeId,
        ticketTypeName: this.attendants[attendantIndex].ticketTypeName,
        userId: this.attendants[attendantIndex].userId,
        name: this.attendants[attendantIndex].name,
        surname1: this.attendants[attendantIndex].surname1,
        surname2: this.attendants[attendantIndex].surname2,
        fullName: this.attendants[attendantIndex].fullName,
        email: this.attendants[attendantIndex].email,
        purchasedAt: this.attendants[attendantIndex].purchasedAt,
        validated: true
      };
    });
  }

  unvalidate(ticketId: string) {

    this.eventService.unvalidateAttendant(ticketId).subscribe(() => {
      const attendantIndex = this.attendants.findIndex(a => a.ticketId === ticketId);

      // Replace attendant
      this.attendants[attendantIndex] = {
        ticketId: this.attendants[attendantIndex].ticketId,
        ticketTypeId: this.attendants[attendantIndex].ticketTypeId,
        ticketTypeName: this.attendants[attendantIndex].ticketTypeName,
        userId: this.attendants[attendantIndex].userId,
        name: this.attendants[attendantIndex].name,
        surname1: this.attendants[attendantIndex].surname1,
        surname2: this.attendants[attendantIndex].surname2,
        fullName: this.attendants[attendantIndex].fullName,
        email: this.attendants[attendantIndex].email,
        purchasedAt: this.attendants[attendantIndex].purchasedAt,
        validated: false
      };
    });
  }

  downloadTemplate(): void {
    window.open('/assets/templates/import.csv', '_blank');
  }

  collapsePanel(): void {
    this.selectedAttendant = null;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { attendantId: null },
      queryParamsHandling: 'merge',
    });
  }

  exportList(): void {
    this.blockDownload = true;
    this.eventService.export(this.eventId).subscribe(
      (response: ExportResponse) => {
        window.open(response.url, '_blank');
        this.blockDownload = false;
      },
      () => {
        this.messageService.add({
          severity: 'error',
          summary: 'Ha ocurrido un error',
          detail: '',
        });
        this.blockDownload = false;
      }
    );
  }

  myUploader(files: any): void {
    this.file = files.files[0];
    this.inviteForm.patchValue({ file: this.file });
    this.inviteForm.get('file').updateValueAndValidity();
  }

  showInviteDialog(): void {
    this.inviteForm.reset({ invitationType: 'individual' });
    this.showInvite = true;
  }

  confirmInvite(individualType: string): void {
    if (individualType === 'individual') {
      this.confirmationService.confirm({
        message:
          'Vas a enviar una invitación a ' +
          this.inviteForm.get('email').value +
          ' para que asista a la experiencia. ¿Estás seguro?',
        header: 'Revisa la invitación',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.eventService
            .inviteUser(this.eventId, this.inviteForm.value)
            .subscribe(
              () => {
                this.messageService.add({
                  severity: 'success',
                  summary: 'Usuario invitado',
                  detail:
                    'El usuario ha sido invitado con éxito',
                });
                this.showInvite = false;
              },
              () => {
                this.messageService.add({
                  severity: 'error',
                  summary: 'La invitación ha fallado',
                  detail: 'Por favor, vuelve a intentarlo de nuevo',
                });
              }
            );
        },
        reject: () => {
          this.showInvite = false;
        },
      });
    } else {
      let ticketTypeId = this.inviteForm.get('ticketType').value;
      let notifyUser = this.inviteForm.get('notifyUser').value;

      this.userService.getSignedURL().subscribe(
        (response: ContentUpload) => {
          this.uploadJob = response;
          if (this.uploadJob.signedUrl !== undefined) {
            this.userService.uploadContent(response.signedUrl, this.file).subscribe(
              (response: any) => {
                if (response.status !== undefined && response.status == 200) {
                  this.userService.executeImport(this.uploadJob.id, ticketTypeId, this.eventId, notifyUser).subscribe(
                    () => {
                      const intervalId = setInterval(() => {
                        this.userService.getImportStatus(this.uploadJob.id).subscribe(
                          (response: JobStatus) => {
                            this.jobStatus = response;
                            if (response.state === 'FINISH_OK' || response.state === 'FINISH_KO') {
                              clearInterval(intervalId);
                              this.cancelInvite();
                              this.loading = false;
                              if (response.state === 'FINISH_KO') {
                                this.messageService.add({
                                  severity: 'error',
                                  summary: 'Error al importar',
                                  detail: 'Por favor, revisa el archivo y vuelve a intentarlo',
                                });
                              } else {
                                this.messageService.add({
                                  severity: 'success',
                                  summary: 'Importación completada',
                                  detail: 'Los usuarios han sido invitados correctamente',
                                });
                              }
                            }
                          },
                          () => {
                            clearInterval(intervalId);
                            this.loading = false;
                          }
                        );
                      }, 3000);
                      this.intervalId = intervalId;
                    },
                    (err: HttpErrorResponse) => {
                      this.loading = false;
                      switch (err.status) {
                        case 409:
                          this.errorMessage = '';
                          break;
                        case 413:
                          this.errorMessage = ``;
                          break;
                        default:
                          this.errorMessage = '';
                          break;
                      }
                    }
                  );
                }
              },
              () => (this.loading = false)
            );
          } else {
            this.loading = false;
          }
        },
        () => (this.loading = false)
      );
    }
  }

  cancelInvite(): void {
    this.showInvite = false;
  }

  clear(table: Table) {
    table.clear();
  }

  hideAddProductDialog(): void {
    this.pressedAssignProduct = false;
  }

  openUser(event): void {
    
    if (event && event.data) {
      this.selectedAttendant = event.data;
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: { id: this.selectedAttendant.ticketId },
        queryParamsHandling: 'merge',
      });
    } else {
      this.selectedAttendant = event;
    }

    this.deliveryService.retrieveSalesByToken(this.selectedAttendant.ticketId).subscribe((sales: Sale[]) => {
      this.selectedAttendantSales = sales;
    });

    this.userService.getTokenAdditionalExperiencesById(this.selectedAttendant.ticketId).subscribe((response: AdditionalExperience[]) => {
      this.selectedAttendantExperiences = response;
    });
  }

  getFilteredTicketTypes(): any[] {
    return this.ticketTypeOptions.filter((type) => type.ticketsNumber - type.ticketsSold > 0);
  }

  hideCustomizationDialog(): void {
    this.displayAddCustomizationDialog = false;
  }

  regenerateToken(): void {
    this.userService.regenerateTicket(this.selectedAttendant.ticketId, this.eventId, this.selectedAttendant.ticketTypeId).subscribe(
      () => {
        this.messageService.add({
          severity: 'success',
          summary: 'Ticket regenerado',
          detail: 'La imagen del ticket fue regenerada correctamente',
        });
      },
      () => {
        this.messageService.add({
          severity: 'error',
          summary: 'Ticket no regenerado',
          detail: 'La imagen del ticket no pudo ser regenerada',
        });
      }
    );
  }

  resendToken(): void {
    this.userService.resendToken(this.selectedAttendant.ticketId).subscribe(
      () => {
        this.messageService.add({
          severity: 'success',
          summary: 'Email enviado',
          detail: 'El email fue enviado correctamente',
        });
      },
      () => {
        this.messageService.add({
          severity: 'error',
          summary: 'Ha ocurrido un error',
          detail: 'No se pudo reenviar el email',
        });
      }
    );
  }

  showAssignDialog(): void {
    this.reassignMail = undefined;
    this.reassignMailCheck = false;
    this.displayAssignDialog = true;
  }

  reassignTicket(): void {
    this.userService.reassignToken(this.selectedAttendant.ticketId, this.reassignMail).subscribe(
      (response: any) => {
        this.messageService.add({
          severity: 'success',
          summary: 'Token reasignado',
          detail: 'El token se reasignó correctamente',
        });
        this.displayAssignDialog = false;
      },
      () => {
        this.messageService.add({
          severity: 'error',
          summary: 'Ha ocurrido un error',
          detail: 'No se pudo reasignar el token',
        });
        this.displayAssignDialog = false;
      }
    );
  }

  viewAll(): void {
    this.router.navigate(['/users', this.selectedAttendant.userId, 'tokens', this.selectedAttendant.ticketId]);
  }
}
