<p-dialog 
  [header]="isEditMode ? 'Editar cupón' : 'Crear cupón'"
  [(visible)]="displayDialog" 
  [modal]="true"
  [style]="{width: '60vw'}" 
  [draggable]="false" 
  [resizable]="false"
  (onHide)="onCancel()"
>
  <form [formGroup]="couponForm" (ngSubmit)="onSubmit()">
    <div class="p-fluid">
      <h3>Configuración general</h3>      
      <div class="p-field">
        <label for="code">Código del cupón *</label>
        <input id="code" type="text" pInputText formControlName="code" [readonly]="isEditMode" minlength="6" maxlength="10" style="text-transform: uppercase;">
        <small class="p-error" *ngIf="couponForm.get('code').errors?.['invalidCouponCode'] && (couponForm.get('code').dirty || couponForm.get('code').touched)">
          El código debe tener entre 6 y 10 caracteres alfanuméricos (letras mayúsculas o números).
        </small>
        <small class="p-error" *ngIf="couponForm.get('code').errors?.['duplicateCouponCode']">
          Este código de cupón ya existe para este evento.
        </small>
      </div>
      
      <div class="p-field-checkbox">
        <p-checkbox formControlName="hasUsesLimit" [binary]="true" inputId="hasUsesLimit"></p-checkbox>
        <label for="hasUsesLimit">Quiero establecer un límite de usos para este cupón</label>
      </div>

      <div class="p-field" *ngIf="couponForm.get('hasUsesLimit').value">
        <label for="usesLimit">
          Límite de usos *
          <span *ngIf="isEditMode && couponToEdit" class="current-uses"> (Usos actuales: {{couponToEdit.currentUses}})</span>
        </label>
        <p-inputNumber id="usesLimit" formControlName="usesLimit" [min]="couponToEdit?.currentUses || 1" [showButtons]="true"></p-inputNumber>
        <small class="p-error" *ngIf="couponForm.get('usesLimit').errors?.['required'] && (couponForm.get('usesLimit').dirty || couponForm.get('usesLimit').touched)">
          El límite de usos es obligatorio cuando se habilita.
        </small>
        <small class="p-error" *ngIf="couponForm.get('usesLimit').errors?.['min'] && (couponForm.get('usesLimit').dirty || couponForm.get('usesLimit').touched)">
          El límite de usos debe ser mayor o igual a los usos actuales (o 1, en caso de crear un nuevo cupón).
        </small>
      </div>
      
      <div class="p-field">
        <label for="discountType">Tipo de descuento *</label>
        <p-dropdown id="discountType" [options]="discountTypes" formControlName="discountType" optionLabel="label" optionValue="value" [disabled]="couponForm.get('discountType').disabled"></p-dropdown>
      </div>
      
      <div class="p-field">
        <label for="discountValue">Descuento a aplicar *</label>
        <div class="p-inputgroup">
          <p-inputNumber id="discountValue" formControlName="discountValue" [minFractionDigits]="2" [maxFractionDigits]="2"></p-inputNumber>
          <span class="p-inputgroup-addon">
            {{ couponForm.get('discountType').value === 'percentage' ? '%' : '€' }}
          </span>
        </div>
        <small class="p-error" *ngIf="couponForm.get('discountValue').errors?.['required'] && (couponForm.get('discountValue').dirty || couponForm.get('discountValue').touched)">
          El descuento es obligatorio.
        </small>
        <small class="p-error" *ngIf="couponForm.get('discountValue').errors?.['min'] && (couponForm.get('discountValue').dirty || couponForm.get('discountValue').touched)">
          El descuento debe ser mayor que 0%.
        </small>
        <small class="p-error" *ngIf="couponForm.get('discountValue').errors?.['maxPercentExceeded'] && (couponForm.get('discountValue').dirty || couponForm.get('discountValue').touched)">
          El descuento no puede ser mayor al 100%.
        </small>
        <small class="p-error" *ngIf="couponForm.get('discountValue').errors?.['minDiscount'] && (couponForm.get('discountValue').dirty || couponForm.get('discountValue').touched)">
          El descuento debe ser válido.
        </small>
        <small class="disabled-message" *ngIf="couponForm.get('discountValue').disabled">
          El valor de descuento no se puede editar porque este cupón ya ha sido utilizado al menos una vez.
        </small>        
      </div>

      <div class="p-field">
        <label for="name">Nombre para mostrar al asistente</label>
        <input id="name" type="text" pInputText formControlName="name">
      </div>

      <h3>Configuración de restricciones</h3>
      <p class="restrictions-info">
        Por defecto, los cupones aplican a todos los productos vendidos para el evento actual. 
        Si deseas restringir su aplicabilidad, marca la casilla a continuación.
      </p>

      <div class="p-field-checkbox">
        <p-checkbox formControlName="hasRestrictions" [binary]="true" inputId="hasRestrictions"></p-checkbox>
        <label for="hasRestrictions">Quiero configurar restricciones para este cupón</label>
      </div>

      <div *ngIf="couponForm.get('hasRestrictions').value">
        <div class="p-field">
          <label for="restrictions">Restringir cupón a * </label>
          <p-multiSelect id="restrictions" [options]="restrictionTypes" formControlName="restrictions" 
                         optionLabel="label" optionValue="value" [appendTo]="'body'"></p-multiSelect>
        </div>
        
        <div class="p-field" *ngIf="couponForm.get('restrictions').value?.includes('tickets')">
          <label for="restrictedTicketTypes">Restringir a los siguientes tickets</label>
          <p-multiSelect id="restrictedTicketTypes" [options]="ticketTypes" formControlName="restrictedTicketTypes" 
                         optionLabel="name" optionValue="id" [appendTo]="'body'"></p-multiSelect>
        </div>
        
        <div class="p-field" *ngIf="couponForm.get('restrictions').value?.includes('complements')">
          <label for="restrictedComplements">Restringir a los siguientes complementos:</label>
          <p-multiSelect id="restrictedComplements" [options]="complements" formControlName="restrictedComplements" 
                         optionLabel="name" optionValue="id" [appendTo]="'body'"></p-multiSelect>
        </div>
        
        <div class="p-field" *ngIf="couponForm.get('restrictions').value?.includes('experiences')">
          <label for="restrictedExperiences">Restringir a las siguientes experiencias:</label>
          <p-multiSelect id="restrictedExperiences" [options]="experiences" formControlName="restrictedExperiences" 
          optionLabel="name" optionValue="id" [appendTo]="'body'"></p-multiSelect>
        </div>
      </div>
     
    </div> 
  </form>
   
  <ng-template pTemplate="footer">
    <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text" (click)="onCancel()"></button>
    <button pButton pRipple [label]="isEditMode ? 'Guardar cambios' : 'Crear cupón'" icon="pi pi-check" class="p-button-text" (click)="onSubmit()" [disabled]="couponForm.invalid"></button>
  </ng-template>
</p-dialog>
