import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { EventTickets } from '../../model/event-detail.interface';
import { EventService } from '../../service/event.service';
import { DateUtil } from "../../../shared/util/DateUtil";

@Component({
  selector: 'app-ticket-item',
  templateUrl: './ticket-item.component.html',
  styleUrls: ['./ticket-item.component.scss']
})
export class TicketItemComponent implements OnInit {

  @Input() index: number;
  @Input() eventId: string;
  @Input() ticket: EventTickets;
  @Output() onUpdate: EventEmitter<EventTickets> = new EventEmitter();
  @Output() onRemove: EventEmitter<number> = new EventEmitter();

  addTicketsDialogVisible: boolean = false;
  generatingTokens: boolean = false;
  newTickets: number = 0;

  messageDialogVisible: boolean = false;

  constructor(private eventService: EventService, private confirmationService: ConfirmationService, private messageService: MessageService) {
  }

  ngOnInit(): void {
  }

  editTicket() {
    this.onUpdate.emit(this.ticket);
  }

  removeTicket() {
    this.confirmationService.confirm({
      message: '¿Estás seguro de que quieres eliminar el ticket?',
      accept: () => {
        if (this.ticket.id) {
          this.eventService.deleteTicket(this.eventId, this.ticket.id).subscribe(() => {
            this.messageService.add({ severity: 'success', summary: 'Ticket borrado correctamente', detail: '' });
            this.onRemove.emit(this.index);
          }, () => {
            this.messageService.add({
              severity: 'error',
              summary: 'Ha ocurrido un error',
              detail: 'Vuelve a intentarlo en unos minutos'
            });
          });
        } else {
          this.onRemove.emit(this.index);
        }
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }

  handleChange($event): void {
    $event.originalEvent.stopPropagation();
    this.eventService.changeTicketStatus(this.eventId, this.ticket.id, $event.checked).subscribe(() => {
      this.messageService.add({ severity: 'success', summary: 'Estado actualizado correctamente', detail: '' });
    }, () => this.messageService.add({
      severity: 'error',
      summary: 'Ha ocurrido un error',
      detail: 'Vuelve a intentarlo en unos minutos'
    }));
  }

  getLocalDate(date: Date): Date | undefined {

    if (date) {

      return DateUtil.fromUTC(date);
    }

    return undefined;
  }

  addTickets(): void {

    this.newTickets = 0;
    this.addTicketsDialogVisible = true;

  }

  generateNewTickets(): void {
    this.generatingTokens = true;
    this.eventService.addTickets(this.eventId, this.ticket.id, { number: this.newTickets }).subscribe(any => {
      this.addTicketsDialogVisible = false;
      this.generatingTokens = false;
      this.messageService.add({ severity: 'success', summary: 'Tokens creados correctamente', detail: '' });
    }, () => {
      this.addTicketsDialogVisible = false;
      this.generatingTokens = false;
      this.messageService.add({
        severity: 'error',
        summary: 'Ha ocurrido un error',
        detail: 'Vuelve a intentarlo en unos minutos'
      });
    });

  }

  createCampaign(): void {

    this.messageDialogVisible = true;
  }

  onMessageDialogHide($event: any): void {

    this.messageDialogVisible = false;
  }

}
