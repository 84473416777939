<div class="stepsdemo-content">
  <p-card>
    <ng-template pTemplate="title"> Mensajes </ng-template>
    <ng-template pTemplate="subtitle">
      Comunícate directamente con los asistentes
    </ng-template>
    <ng-template pTemplate="content">
      <div class="flex flex-column">
        <div class="flex-grow-1 mr-3">
          <p-editor placeholder="Cuéntale algo a tus fans" [(ngModel)]="newMessage" [style]="{ height: '100px' }">
            <ng-template pTemplate="header">
              <span class="ql-formats">
                <select class="ql-header">
                  <option value="1"></option>
                  <option value="2"></option>
                  <option value="3"></option>
                  <option value="4"></option>
                  <option value="5"></option>
                  <option value="6"></option>
                  <option value="false" selected></option>
                </select>
                <button type="button" class="ql-bold" aria-label="Bold"></button>
                <button type="button" class="ql-italic" aria-label="Italic"></button>
                <button type="button" class="ql-underline" aria-label="Underline"></button>
                <button type="button" class="ql-strike" aria-label="Strike"></button>
                <button type="button" class="ql-list" value="ordered"></button>
                <button type="button" class="ql-list" value="bullet"></button>
              </span>
            </ng-template>
          </p-editor>
          <div class="mt-3">
            <p-checkbox id="customizeRedirection" [binary]="true" styleClass="mr-2"
              label="Quiero personalizar la sección destino del usuario cuando pulsa en la notificación."
              [(ngModel)]="customRedirection"></p-checkbox>
            <div *ngIf="customRedirection" class="mt-3">
              <p-dropdown class="mr-3" [options]="serviceOptions" [(ngModel)]="selectedService"
                placeholder="Selecciona una sección"></p-dropdown>
            </div>
          </div>
        </div>
        <div class="flex flex-row justify-content-end mt-3 mr-3">
          <p-button icon="pi pi-send" label="Enviar" (click)="sendMessage()"
          [disabled]="!newMessage || newMessage?.trim().length === 0 || loading"
          [loading]="loading"></p-button>
        </div>
      </div>
      <div class="flex flex-column">
        <p-card class="message mt-3" [subheader]="message.publishedAt | date : 'full'" *ngFor="let message of messages">
          <div class="w-full flex flex-row">
            <div class="flex-grow-1">
              <div [innerHTML]="message.content"></div>
            </div>
            <div>
              <p-button styleClass="p-button-text" icon="pi pi-trash" (click)="deleteMessage(message.id)"></p-button>
            </div>
          </div>
        </p-card>
      </div>
    </ng-template>
  </p-card>
</div>