import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'events', loadChildren: () => import('./event/event.module').then(m => m.EventModule)
  },
  {
    path: 'users', loadChildren: () => import('./user/user.module').then(m => m.UserModule)
  },
  {
    path: 'lists', loadChildren: () => import('./list/list.module').then(m => m.ListModule)
  },
  {
    path: 'products', loadChildren: () => import('./merch/merch.module').then(m => m.MerchModule)
  },
  {
    path: 'campaigns', loadChildren: () => import('./campaign/campaign.module').then(m => m.CampaignModule)
  },
  {
    path: 'web', loadChildren: () => import('./web/web.module').then(m => m.WebModule)
  },
  {
    path: 'settings', loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule)
  },
  {
    path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: '', redirectTo: 'home', pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
