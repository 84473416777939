import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { FormPart } from '../../model/form-part.interface';
import { EventService } from '../../service/event.service';
import { PriceSimulation } from '../../model/price-simulation.interface';
import { MessageService } from 'primeng/api';
import { Ticket } from '../../model/ticket.interface';
import { EventTickets } from '../../model/event-detail.interface';
import { CreatedEntity } from '../../model/created-entity.interface';
import { PriceChange } from '../../model/price-change.interface';
import { Store } from '@ngrx/store';
import { getLoggedInUser } from 'src/app/auth/store';
import { DateUtil } from '../../../shared/util/DateUtil';
import { group } from '@angular/animations';
import { TicketCreate } from '../../model/ticket-create.interface';
import { TicketPosition } from '../../model/ticket-position.interface';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-step-tickets',
  templateUrl: './step-tickets.component.html',
  styleUrls: ['./step-tickets.component.scss'],
})
export class StepTicketsComponent implements OnInit, OnChanges {
  selectedTicketSold: boolean = false;

  maxSellDate: any;
  minSellEndDate: any;

  ticketsSold: boolean = false;

  priceSimulation: PriceSimulation;

  defaultPrice: number = 0;
  includeTaxes: boolean = false;

  previousPrice: number;
  previousDiscount: number;

  display: boolean = false;
  update: boolean = false;
  creatingTicket: boolean = false;

  ticketFee: number;

  uploadedFiles: any[] = [];

  ticketForm = this.fb.group(
    {
      id: this.fb.control('', []),
      name: this.fb.control('', [Validators.required]),
      description: this.fb.control('', []),
      ticketsNumber: this.fb.control(0, [
        Validators.required,
        Validators.max(100000),
      ]),
      regularPrice: this.fb.control(0, [Validators.required]),
      displayPrice: this.fb.control(0, [Validators.required]),
      includeTaxes: this.fb.control(false, []),
      fanDiscount: this.fb.control(0, []),
      sellDate: this.fb.control(new Date(), [Validators.required]),
      sellEndDate: this.fb.control(new Date(), []),
      fanSellDate: this.fb.control(new Date(), []),
      imageUrl: this.fb.control(null),
      assignation: this.fb.group({
        allowDuplicatedEmail: this.fb.control(false),
        fields: this.fb.group({
          name: this.fb.group({
            enabled: this.fb.control(false),
            required: this.fb.control(false),
          }),
          surname: this.fb.group({
            enabled: this.fb.control(false),
            required: this.fb.control(false),
          }),
          phone: this.fb.group({
            enabled: this.fb.control(false),
            required: this.fb.control(false),
          }),
          fiscalId: this.fb.group({
            enabled: this.fb.control(false),
            required: this.fb.control(false),
          }),
          genre: this.fb.group({
            enabled: this.fb.control(false),
            required: this.fb.control(false),
          }),
          birth: this.fb.group({
            enabled: this.fb.control(false),
            required: this.fb.control(false),
          }),
        }),
        additional: this.fb.array([]),
      }),
    },
    { validators: this.dateValidator }
  );

  @Output() next: EventEmitter<FormPart> = new EventEmitter();
  @Output() save: EventEmitter<FormPart> = new EventEmitter();

  @Input() data: Ticket[] = [];
  @Input() startDate: any;
  @Input() eventId: string;
  msgs: any[];
  descriptionForm: any;

  constructor(
    private eventService: EventService,
    private messageService: MessageService,
    private store: Store,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.previousPrice = undefined;
    this.previousDiscount = undefined;
    this.store.select(getLoggedInUser).subscribe((observer) => {
      this.ticketFee = observer?.ticketFee;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'] && changes['data'].currentValue) {
      const tickets: Ticket[] = changes['data'].currentValue;

      // Sort tickets based on displayPosition
      this.data = this.sortTicketsByDisplayPosition(tickets);

      // SFM (31/08/22): If we sold any ticket, we MUST block the admin to modify the tickets
      this.ticketsSold = tickets.some(ticket => ticket.ticketsSold > 0);

    }
  }

  private sortTicketsByDisplayPosition(tickets: Ticket[]): Ticket[] {
    return tickets.sort((a, b) => {
      if (a.displayPosition == null && b.displayPosition == null) {
        return 0; // Both have no displayPosition
      }
      if (a.displayPosition == null) {
        return 1; // a has no displayPosition, push it after b
      }
      if (b.displayPosition == null) {
        return -1; // b has no displayPosition, push it after a
      }
      return a.displayPosition - b.displayPosition; // Both have displayPosition, sort ascendingly
    });
  }

  dateValidator(control: AbstractControl): { [key: string]: any } | null {
    const sellDate = control.get('sellDate')?.value;
    const sellEndDate = control.get('sellEndDate')?.value;
    return sellEndDate && sellDate && sellEndDate < sellDate
      ? { sellEndDateInvalid: true }
      : null;
  }

  nextPage() {
    this.next.emit({
      next: 1,
      name: 'tickets',
      data: this.objectToFormArray(),
    });
  }

  prevPage() {
    this.next.emit({
      next: -1,
      name: 'tickets',
      data: this.objectToFormArray(),
    });
  }

  setMaxDate($event: any) {
    this.maxSellDate = $event;
  }

  setMinSellEndDate() {
    this.minSellEndDate = this.ticketForm.get('sellDate').value;
  }

  // FIXME refactor unnecessary logic with parent form
  private objectToFormArray(): FormArray {
    let tickets: FormArray = this.fb.array([]);

    // if the array is not defined, create an empty one
    if (!this.data) {
      this.data = [];
    }

    for (const ticket of this.data) {
      tickets.push(this.createTicketFormGroup(ticket));
    }

    // Return the tickets form array
    return tickets;
  }

  createTicketFormGroup(ticket: Ticket): FormGroup {
    const formGroup: FormGroup = this.fb.group({
      id: this.fb.control('', []),
      name: this.fb.control('', [Validators.required]),
      description: this.fb.control('', []),
      ticketsNumber: this.fb.control(0, [
        Validators.required,
        Validators.max(100000),
      ]),
      regularPrice: this.fb.control(0, [Validators.required]),
      displayPrice: this.fb.control(0, [Validators.required]),
      includeTaxes: this.fb.control(false, []),
      fanDiscount: this.fb.control(0, []),
      sellDate: this.fb.control(new Date(), [Validators.required]),
      sellEndDate: this.fb.control(new Date(), []),
      fanSellDate: this.fb.control(new Date(), []),
      imageUrl: this.fb.control(''),
      assignation: this.fb.group({
        allowDuplicatedEmail: this.fb.control(false),
        fields: this.fb.group({
          name: this.fb.group({
            enabled: this.fb.control(false),
            required: this.fb.control(false),
          }),
          surname: this.fb.group({
            enabled: this.fb.control(false),
            required: this.fb.control(false),
          }),
          phone: this.fb.group({
            enabled: this.fb.control(false),
            required: this.fb.control(false),
          }),
          fiscalId: this.fb.group({
            enabled: this.fb.control(false),
            required: this.fb.control(false),
          }),
          genre: this.fb.group({
            enabled: this.fb.control(false),
            required: this.fb.control(false),
          }),
          birth: this.fb.group({
            enabled: this.fb.control(false),
            required: this.fb.control(false),
          }),
        }),
        additional: this.fb.array([]),
      }),
    });

    formGroup.patchValue({
      id: ticket.id || '',
      name: ticket.name || '',
      description: ticket.description || '',
      ticketsNumber: ticket.ticketsNumber || 0,
      regularPrice: ticket.regularPrice || 0,
      displayPrice: ticket.displayPrice || 0,
      includeTaxes: ticket.includeTaxes || false,
      fanDiscount: ticket.fanDiscount || 0,
      sellDate: ticket.sellDate ? new Date(ticket.sellDate) : new Date(),
      sellEndDate: ticket.sellEndDate ? new Date(ticket.sellEndDate) : null,
      fanSellDate: ticket.fanSellDate ? new Date(ticket.fanSellDate) : null,
      imageUrl: ticket.imageUrl || '',
      assignation: {
        allowDuplicatedEmail: ticket.assignation?.allowDuplicatedEmail || false,
        fields: {
          name: {
            enabled: ticket.assignation?.fields?.name?.enabled || false,
            required: ticket.assignation?.fields?.name?.required || false,
          },
          surname: {
            enabled: ticket.assignation?.fields?.surname?.enabled || false,
            required: ticket.assignation?.fields?.surname?.required || false,
          },
          phone: {
            enabled: ticket.assignation?.fields?.phone?.enabled || false,
            required: ticket.assignation?.fields?.phone?.required || false,
          },
          fiscalId: {
            enabled: ticket.assignation?.fields?.fiscalId?.enabled || false,
            required: ticket.assignation?.fields?.fiscalId?.required || false,
          },
          genre: {
            enabled: ticket.assignation?.fields?.genre?.enabled || false,
            required: ticket.assignation?.fields?.genre?.required || false,
          },
          birth: {
            enabled: ticket.assignation?.fields?.birth?.enabled || false,
            required: ticket.assignation?.fields?.birth?.required || false,
          },
        },
      },
    });
  
    // Populate the additional fields
    const additionalFieldsArray = formGroup.get('assignation.additional') as FormArray;
    if (ticket.assignation?.additional && ticket.assignation.additional.length > 0) {
      ticket.assignation.additional.forEach((ad: any) => {
        const fieldGroup = this.fb.group({
          name: [ad.name, Validators.required],
          type: [ad.type, Validators.required],
          required: [ad.required],
          description: [ad.description],
          options: this.fb.array([]),  // Create empty FormArray for options
        });
  
        // Handle options for select fields
        if (ad.type === 'single-select' || ad.type === 'multi-select') {
          const optionsArray = fieldGroup.get('options') as FormArray;
          if (ad.options && ad.options.length > 0) {
            ad.options.forEach((option: string) => {
              // Ensure that each option is properly initialized as a FormControl
              optionsArray.push(this.fb.control(option, Validators.required));
            });
          }
        }
  
        additionalFieldsArray.push(fieldGroup);
      });
    }
  
    return formGroup;
  }

  saveDraft(): void {
    this.save.emit({
      next: 0,
      name: 'tickets',
      data: this.objectToFormArray(),
    });
  }

  addTicket() {
    this.selectedTicketSold = false;
    this.ticketForm.get('ticketsNumber').enable();
    this.update = false;
    this.defaultPrice = 0;
    this.includeTaxes = false;
    this.ticketForm.reset();
    this.ticketForm.patchValue({
      sellDate: new Date(),
      regularPrice: 0,
      displayPrice: 0,
      includeTaxes: false,
    });

    const additionalFieldsArray = this.ticketForm.get('assignation.additional') as FormArray;
    while (additionalFieldsArray.length !== 0) {
      additionalFieldsArray.removeAt(0);
    }

    this.display = true;
    this.ticketForm.markAsDirty();
  }

  myUploader(event: any) {
    const file = event.files[0];
    const imageName: string = self.crypto.randomUUID();
    const mimeType = file.type;
  
    this.eventService.getSignedUrl(imageName, mimeType).subscribe(signedUrl => {
      this.eventService.uploadImage(signedUrl.signedUrl, mimeType, file).subscribe(response => {
        if (response.status === 200) {
          this.uploadedFiles.push(file);
          this.ticketForm.patchValue({ imageUrl: signedUrl.destinationUrl });
          this.ticketForm.markAsDirty();
        }
      });
    });
  }

  createTicket() {
    this.creatingTicket = true;

    if (this.ticketForm.invalid) {
      this.messageService.add({
        severity: 'error',
        summary: 'Formulario inválido',
        detail: 'Por favor, revisa todos los campos y asegúrate de que estén correctamente rellenados.'
      });
      this.creatingTicket = false;
      return;
    }

    const ticketData = this.ticketForm.value;
  
    // Process options for additional fields
    if (ticketData.assignation && ticketData.assignation.additional) {
      ticketData.assignation.additional = ticketData.assignation.additional.map((field: any) => {
        if (field.type === 'single-select' || field.type === 'multi-select') {
          // Convert options FormArray to array of strings
          field.options = field.options.map((optionControl: any) => optionControl);
        } else {
          // Remove options property if not applicable
          delete field.options;
        }
        return field;
      });
    }
  
    if (this.eventId) {
      // Check if it's an update or new ticket creation
      const ticketId = this.ticketForm.get('id').value;
  
      if (ticketId) {
        // Update ticket
        this.eventService.updateTicket(this.eventId, ticketId, ticketData).subscribe(
          () => {
            this.messageService.add({
              severity: 'success',
              summary: 'Entrada modificada correctamente',
              detail: '',
            });
            this.display = false;
            this.creatingTicket = false;
            this.updateTicketInList(ticketData);
            window.location.reload();  // Hotfix to KAN-63
          },
          () => {
            this.messageService.add({
              severity: 'error',
              summary: 'Ha ocurrido un error',
              detail: 'Revisa los datos e inténtalo de nuevo',
            });
            this.creatingTicket = false;
          }
        );
      } else {
        // Create new ticket
        this.eventService.addTicket(this.eventId, ticketData).subscribe(
          (response: CreatedEntity) => {
            const newTicket: any = { ...ticketData, id: response.created, ticketsSold: 0 };
            if (!this.data) this.data = [];
            this.data.push(newTicket);
            this.display = false;
            this.creatingTicket = false;
            this.messageService.add({
              severity: 'success',
              summary: 'Entrada creada correctamente',
              detail: 'Recarga la página para ver los cambios',
            });
          },
          () => {
            this.messageService.add({
              severity: 'error',
              summary: 'Ha ocurrido un error',
              detail: 'Revisa los datos e inténtalo de nuevo',
            });
            this.creatingTicket = false;
          }
        );
      }
    } else {
      // Handle ticket creation without an event ID
      if (!this.data) this.data = [];
      this.data.push(this.ticketForm.value);
      this.creatingTicket = false;
      this.display = false;
    }
  }
  
  

  updateTicketInList(updatedTicket: TicketCreate) {
    const index = this.data.findIndex(
      (ticket) => ticket.id === updatedTicket.id
    );
    if (index !== -1) {
      // Manually update the ticket object in the list
      this.data[index] = { ...this.data[index], ...updatedTicket };
    }
  }

  updateTicket($event: EventTickets) {
    this.update = true;
    this.selectedTicketSold = false;
  
    const regularPrice = $event.regularPrice / 100;
    const displayPrice = $event.displayPrice / 100;
  
    this.defaultPrice = $event.includeTaxes ? displayPrice : regularPrice;
    this.includeTaxes = $event.includeTaxes;
  
    // Clear existing additional fields
    const additionalFieldsFormArray = this.ticketForm.get('assignation.additional') as FormArray;
    while (additionalFieldsFormArray.length !== 0) {
      additionalFieldsFormArray.removeAt(0);
    }
  
    // Patch additional fields if they exist
    if ($event.assignation?.additional?.length > 0) {
      $event.assignation.additional.forEach((ad) => {
        const fieldGroup = this.fb.group({
          name: [ad.name, Validators.required],
          type: [ad.type, Validators.required],
          description: [ad.description || ''],
          required: [ad.required],
          options: this.fb.array([])
        });
  
        // Handle options for single-select and multi-select fields
        if (ad.type === 'single-select' || ad.type === 'multi-select') {
          const optionsArray = fieldGroup.get('options') as FormArray;
          if (ad.options && ad.options.length > 0) {
            ad.options.forEach((option: string) => {
              optionsArray.push(this.fb.control(option, Validators.required));
            });
          }
        }
  
        additionalFieldsFormArray.push(fieldGroup);
      });
    }
  
    this.ticketForm.patchValue({
      id: $event.id,
      name: $event.name,
      description: $event.description,
      imageUrl: $event.imageUrl,
      ticketsNumber: $event.ticketsNumber,
      regularPrice: regularPrice,
      displayPrice: displayPrice,
      includeTaxes: $event.includeTaxes,
      fanDiscount: $event.discount,
      sellDate: DateUtil.fromUTC($event.sellDate),
      sellEndDate: $event.sellEndDate ? DateUtil.fromUTC($event.sellEndDate) : null,
      fanSellDate: $event.fanSellDate ? DateUtil.fromUTC($event.fanSellDate) : null,
      assignation: {
        allowDuplicatedEmail: $event.assignation.allowDuplicatedEmail,
        fields: {
          name: {
            enabled: $event.assignation.fields.name.enabled,
            required: $event.assignation.fields.name.required,
          },
          surname: {
            enabled: $event.assignation.fields.surname.enabled,
            required: $event.assignation.fields.surname.required,
          },
          phone: {
            enabled: $event.assignation.fields.phone.enabled,
            required: $event.assignation.fields.phone.required,
          },
          fiscalId: {
            enabled: $event.assignation.fields.fiscalId.enabled,
            required: $event.assignation.fields.fiscalId.required,
          },
          genre: {
            enabled: $event.assignation.fields.genre.enabled,
            required: $event.assignation.fields.genre.required,
          },
          birth: {
            enabled: $event.assignation.fields.birth.enabled,
            required: $event.assignation.fields.birth.required,
          },
        },
      },
    });
  
    this.maxSellDate = new Date($event.sellDate);
    this.setMinSellEndDate();
  
    if ($event.ticketsSold > 0) {
      this.selectedTicketSold = true;
    }
  
    this.ticketForm.markAsDirty();
    this.display = true;
  }

  removeTicketFromList(index: number) {
    if (index !== -1) {
      this.data.splice(index, 1);
    }
  }

  onTicketPriceChange($event: PriceChange) {
    this.ticketForm.patchValue({
      regularPrice: $event.basePrice,
      displayPrice: $event.finalPrice,
      includeTaxes: $event.includeTaxes,
    });

    this.ticketForm.markAsDirty();
    this.ticketForm.markAsTouched();
  }

  ticketIndex(ticket: Ticket): number {
    return this.data.indexOf(ticket);
  }

  onReorder(event: any): void {

    const updatedPositions: TicketPosition[] = this.data.map((ticket, index) => ({
      ticketId: ticket.id!,
      displayPosition: index
    }));

    this.eventService.updateTicketPositions(this.eventId, updatedPositions)
    .pipe(
      finalize(() => {
        // Any cleanup would go here
      })
    )
    .subscribe(
      () => {
        this.messageService.add({
          severity: 'success',
          summary: 'Posiciones actualizadas',
          detail: 'Las entradas han sido reordenadas correctamente.'
        });
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error al actualizar posiciones',
          detail: 'No se pudo guardar el nuevo orden de las entradas. Inténtalo de nuevo.'
        });
        // revert the changes in case of an error (restore original order)
      }
    );

  }

}
