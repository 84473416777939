<h2>Resumen de las ventas</h2>
<div class="flex align-items-start justify-content-start align-items-center analytics-row">
  <div #chartSalesStatus class="box-25"></div>
  <div #chartTotalBilled class="box-25"></div>
  <div #chartTotalUpselling class="box-25"></div>
</div>
<div class="flex mt-3">
  <div #chartTokensByType class="box-50"></div>
  <div #chartExperiencesByType class="box-50"></div>
</div>
<div class="flex mt-3">
  <div #chartProductsByType class="box-50"></div>
  <div #chartSalesByAffiliate class="box-50"></div>
</div>
<div class="flex mt-3"></div>