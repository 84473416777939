import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SignedURL } from 'src/app/event/model/signed-url.interface';
import { environment } from 'src/environments/environment';
import { DomainExists } from '../model/domain-exists.interface';
import { Organization } from '../model/organization.interface';
import {CookieService} from "ngx-cookie-service";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  baseUrl: string =environment.apiUrl;

  constructor(private http: HttpClient, private cookieService: CookieService) { }

  getOrganization(): Observable<Organization> {

    const url = `${this.baseUrl}/tenants`

    return this.http.get<Organization>(url);
  }

  checkDomain(domain: string): Observable<any> {
    const url = `${this.baseUrl}/tenants/${domain}/exists`

    return this.http.get<DomainExists>(url);
  }

  getSignedUrl(imageId: string, mimeType: string): Observable<SignedURL> {

    const url = `${this.baseUrl}/tenants/${imageId}/upload`

    return this.http.get<SignedURL>(url, { params: { mimeType } });
  }

  complete(data: any): Observable<void> {

    const url = `${this.baseUrl}/tenants`

    // Adds idToken to allow extracting name and surname on the backend
    data.idToken = this.cookieService.get('id_token');

    return this.http.post<void>(url, data);
  }

  uploadImage(fileuploadurl, contenttype, file): Observable<any> {

    const headers = new HttpHeaders({ 'Content-Type': contenttype });
    const req = new HttpRequest(
      'PUT',
      fileuploadurl,
      file,
      {
        headers: headers,
        reportProgress: true, //This is required for track upload process
      });
    return this.http.request(req);
  }

  isTenantConfigured(): Observable<DomainExists> {

    const url = `${this.baseUrl}/tenants/configured`

    return this.http.get<DomainExists>(url);
  }

}
